<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="tableVisits"
      :options.sync="options"
      :server-items-length="totalVisits"
      :items-per-page="12"
      :footer-props="{
        'disable-items-per-page': true,
        itemsPerPageOptions: [12],
      }"
      @page-count="pageCount = $event"
      no-data-text="No tiene visitas asignadas"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          v-if="dialog"
          max-width="600px"
          persistent
          scrollable
        >
          <!-- <v-form @submit.prevent="submitForm"> -->
          <v-card>
            <v-card-title>
              <v-tabs
                v-model="activeTab"
                background-color="transparent"
                color="primary"
                show-arrows
                grow
              >
                <v-tab>Asistencia</v-tab>
                <v-tab :disabled="disabledTab">Visitas</v-tab>
                <v-tab :disabled="disabledTab">Mensajes</v-tab>
              </v-tabs>
            </v-card-title>

            <v-card-text id="divCardText">
              <v-tabs-items v-model="activeTab">
                <v-tab-item
                  v-for="(tabComponent, index) in tabsComponents"
                  :key="index"
                >
                  <component
                    :is="tabComponent.component"
                    :supportId="selectedItem.supportId"
                    :farmerId="selectedItem.farmerId"
                    :status="selectedItem.status"
                    @loading="overlay = $event"
                    @closeDialog="close"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn color="grey darken-1" text @click="close"> Cerrar </v-btn>
            </v-card-actions>

            <v-overlay :value="overlay" absolute>
              <v-progress-circular indeterminate size="48" />
            </v-overlay>
          </v-card>
          <!-- </v-form> -->
        </v-dialog>

        <v-dialog
          v-model="dialogVisits"
          v-if="dialogVisits"
          max-width="600px"
          persistent
          scrollable
        >
          <v-card>
            <v-card-title>
              <span class="text-button grey--text text--darken-1">
                Recomendaciones a
                {{
                  `${selectedItem.farmer.name}  ${selectedItem.farmer.lastname}`
                }}
              </span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-spacer />
                  <v-btn
                    small
                    outlined
                    color="primary"
                    @click="dialogVisitObservation = true"
                  >
                    {{ selectedItem.description ? 'Editar' : 'Añadir' }}
                    observación a visita
                  </v-btn>
                </v-col>
              </v-row>
              <p
                v-if="selectedItem.support.recommendations.length == 0"
                class="mb-0"
              >
                No se realizaron recomendaciones
              </p>
              <v-row
                class="mt-0"
                style="position: relative"
                v-for="(recommendation, index) in selectedItem.support
                  .recommendations"
                :key="index"
              >
                <v-col cols="12">
                  <v-textarea
                    :value="recommendation.description"
                    :hint="dateToHuman(recommendation.date)"
                    persistent-hint
                    dense
                    auto-grow
                    rows="1"
                    readonly
                  />
                </v-col>
                <v-col cols="12">
                  <div class="caption">Productos recomendados</div>
                  <p
                    v-if="recommendation.recommendedProducts.length == 0"
                    class="mb-0"
                  >
                    No se recomendaron productos
                  </p>
                  <v-simple-table dense v-else>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Nombre</th>
                          <th class="text-left">Ingrediente Activo</th>
                          <th class="text-left">Dosis</th>
                          <th class="text-left">Cant. Agua</th>
                          <th class="text-left">Aplicado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            product, i
                          ) in recommendation.recommendedProducts"
                          :key="i"
                        >
                          <td>{{ product.name }}</td>
                          <td>{{ product.activeIngredient }}</td>
                          <td>{{ product.dosage }}</td>
                          <td>{{ product.waterQty }}</td>
                          <td>
                            <v-checkbox
                              dense
                              hide-details
                              class="mt-0 pt-0"
                              v-model="
                                recommendedProductsApplied['p' + index][i]
                              "
                            />
                          </td>
                          <v-overlay
                            :value="false"
                            :ref="`overlay${index}`"
                            absolute
                          >
                            <v-progress-circular indeterminate size="48" />
                          </v-overlay>
                        </tr>
                      </tbody>
                      <!--
                      <tfoot>
                        <tr>
                          <td colspan="5">
                            <span class="caption">
                              Recomendaciones productos:
                            </span>
                            <br />
                            {{ recommendation.productsObservations }}
                          </td>
                        </tr>
                      </tfoot>
                      -->
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-spacer />
                  <v-btn
                    :disabled="recommendation.recommendedProducts.length == 0"
                    outlined
                    small
                    color="primary"
                    @click="saveAreAppliedProducts(index)"
                  >
                    Guardar aplicación de productos
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="index + 1 < selectedItem.support.recommendations.length"
                  class="my-4 pt-0"
                >
                  <v-divider />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn color="grey darken-1" text @click="dialogVisits = false">
                Cerrar
              </v-btn>
            </v-card-actions>

            <v-overlay :value="overlay" absolute>
              <v-progress-circular indeterminate size="48" />
            </v-overlay>
          </v-card>
        </v-dialog>

        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <v-dialog
            v-if="dialogVisitObservation"
            v-model="dialogVisitObservation"
            max-width="600px"
            persistent
            scrollable
          >
            <v-form @submit.prevent="addObservation">
              <v-card>
                <v-card-title>
                  <span class="text-button grey--text text--darken-1">
                    {{ selectedItem.description ? 'Editar' : 'Añadir' }}
                    observación a visita
                  </span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          name="la observación"
                        >
                          <v-textarea
                            v-model="visitObservation"
                            dense
                            auto-grow
                            rows="3"
                            label="Observación*"
                            :error-messages="errors"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>*Indica campo requerido</small>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />

                  <v-btn
                    color="grey darken-1"
                    text
                    @click="dialogVisitObservation = false"
                  >
                    Cancelar
                  </v-btn>

                  <v-btn
                    type="submit"
                    color="green darken-2"
                    :disabled="invalid"
                    text
                  >
                    {{ selectedItem.description ? 'Editar' : 'Añadir' }}
                    observación
                  </v-btn>
                </v-card-actions>

                <v-overlay :value="overlayVisitObservation" absolute>
                  <v-progress-circular indeterminate size="48" />
                </v-overlay>
              </v-card>
            </v-form>
          </v-dialog>
        </ValidationObserver>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" small @click="openVisitsDialog(item)">
          mdi-format-list-bulleted-square
        </v-icon>
        <v-icon small @click="openDatasheet(item)"> mdi-eye </v-icon>
      </template>

      <template #footer.prepend>
        <v-spacer class="py-6" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from '@/localdb/db.js';

import { mapGetters } from 'vuex';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('es');
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.tz.setDefault('America/Bogota');

import gql from 'graphql-tag';

import SupportSheetTab from '@/components/support/supportTabs/SupportSheetTab.vue';
import SupportVisitsTab from '@/components/support/supportTabs/SupportVisitsTab.vue';
import SupportMessageTab from '@/components/support/supportTabs/SupportMessageTab.vue';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

export default {
  name: 'Visits',

  components: {
    SupportSheetTab,
    SupportVisitsTab,
    SupportMessageTab,
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
    overlay: false,
    overlayVisitObservation: false,

    dialog: false,
    dialogVisits: false,
    dialogVisitObservation: false,
    visitObservation: '',
    search: '',
    loading: false,
    options: {},
    totalVisits: 0,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Fecha',
        align: 'start',
        sortable: false,
        value: 'humanDate',
      },
      { text: 'Productor', sortable: false, value: 'farmerName' },
      { text: 'Teléfono', sortable: false, value: 'mobile' },
      { text: 'Municipio', sortable: false, value: 'town' },
      { text: 'Vereda', sortable: false, value: 'location' },
      { text: 'Finca', sortable: false, value: 'farmName' },
      { text: 'Acciones', sortable: false, value: 'actions', align: 'end' },
    ],
    visits: [],
    tableVisits: [],
    selectedIndex: -1,
    selectedItem: {},
    defaultItem: {},

    recommendedProductsApplied: {},

    tabsComponents: [
      { component: SupportSheetTab },
      { component: SupportVisitsTab },
      { component: SupportMessageTab },
    ],
    activeTab: 0,
  }),

  /*
  beforeRouteEnter(to, from, next) {
    // next({ name: 'perfil' }); // Redirecciona a la ruta
    next();
  },
  */

  computed: {
    ...mapGetters({
      userRole: 'userData/userRole',
      isConnected: 'network/isConnected',
    }),

    disabledTab() {
      return this.userRole == 'TECHNICIAN';
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.selectedItem = Object.assign({}, this.defaultItem);
  },

  async mounted() {
    await this.initialize();

    // Sobreescribo el estado de los productos cuando hayan sido modificados de manera offline
    if (!this.isConnected) {
      const localRecommendations = await db.recommendedProduct.toArray();
      const localObservations = await db.visitObservation.toArray();

      if (localRecommendations.length == 0 && localObservations.length == 0)
        return;

      this.visits.forEach((visit) => {
        // Actualizo el estado de los productos recomendados
        if (localRecommendations.length > 0) {
          visit.support.recommendations.forEach((recommendation) => {
            const localRecommendation = localRecommendations.find(
              ({ id }) => recommendation.id == id
            );

            if (localRecommendation) {
              recommendation.recommendedProducts.forEach((rec, i) => {
                rec.status =
                  localRecommendation.data.variables.input.recommendedProducts[
                    i
                  ].status;
              });
            }
          });
        }

        // Actualizo las observaciones de las visitas
        if (localObservations.length > 0) {
          const localObservation = localObservations.find(
            ({ id }) => visit.id == id
          );

          if (localObservation) {
            visit.description =
              localObservation.data.variables.input.description;
          }
        }
      });
    }
  },

  methods: {
    async initialize() {
      this.loading = true;

      const query = gql`
        query GetVisitsPaginated($input: GetVisitsPaginatedInput) {
          getVisitsPaginated(input: $input) {
            visits {
              id
              date
              hour
              description
              supportId

              farmer {
                name
                lastname
                farmName
                mobile

                town {
                  name
                }

                location {
                  name
                }
              }

              support {
                recommendations {
                  id
                  date
                  description
                  productsObservations

                  recommendedProducts {
                    id
                    name
                    activeIngredient
                    dosage
                    waterQty
                    status
                  }
                }
              }
            }

            total
          }
        }
      `;

      const {
        data: { getVisitsPaginated },
      } = await this.$apollo.query({
        query,
        variables: {
          input: {
            page: this.options.page,
            perPage: this.options.itemsPerPage,
          },
        },
        fetchPolicy: 'network-only',
      });

      this.visits = getVisitsPaginated.visits;
      this.totalVisits = getVisitsPaginated.total;

      const tableVisits = [];
      getVisitsPaginated.visits.map((visit) => {
        tableVisits.push({
          id: visit.id,
          supportId: visit.supportId,
          humanDate: dayjs(visit.date + 'T' + visit.hour).format('LL h:mm a'),
          farmerName: visit.farmer.name + ' ' + visit.farmer.lastname,
          mobile: visit.farmer.mobile,
          town: visit.farmer.town.name,
          location: visit.farmer.location.name,
          farmName: visit.farmer.farmName
            ? visit.farmer.farmName
            : 'No registrado',
        });
      });

      this.tableVisits = tableVisits;

      this.loading = false;
    },

    async addObservation() {
      this.overlayVisitObservation = true;

      const variables = {
        visitId: this.selectedItem.id,
        description: this.visitObservation,
      };

      const mutation = gql`
        mutation updateVisitDescription(
          $input: DataUpdateVisitDescriptionInput
        ) {
          updateVisitDescription(input: $input) {
            success
            message
          }
        }
      `;

      const {
        data: { updateVisitDescription },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
        })
        .catch(async () => {
          const dataToSave = {
            token: localStorage.getItem('token') || null,
            refresh: localStorage.getItem('refresh') || null,

            variables: { input: variables },
          };

          if (!this.isConnected) {
            // Guardo los datos localmente
            await db.visitObservation.put({
              id: this.selectedItem.id,
              data: dataToSave,
            });

            // Actualizo la información en memoria
            this.visits[this.selectedIndex].description = this.visitObservation;

            // Registro el evento sync para que se ejecute cuando tenga conexión
            navigator.serviceWorker.getRegistration().then((reg) => {
              reg.sync.register('update-visit-observation');
            });

            this.$store.commit('snackbar/setSnack', {
              message: 'Observación registrada correctamente',
              color: 'success',
            });

            this.overlayVisitObservation = false;
            this.dialogVisitObservation = false;
          }
        });

      if (updateVisitDescription.success) {
        // Actualizo la información en memoria
        this.visits[this.selectedIndex].description = this.visitObservation;
        this.initialize();
      }

      this.$store.commit('snackbar/setSnack', {
        message: updateVisitDescription.message,
        color: updateVisitDescription.success ? 'success' : 'error',
      });

      this.overlayVisitObservation = false;
      this.dialogVisitObservation = false;
    },

    async saveAreAppliedProducts(index) {
      this.$refs[`overlay${index}`][0].value = true;

      const appliedProducts = [];
      this.selectedItem.support.recommendations[index].recommendedProducts.map(
        (recommendedProduct, i) => {
          appliedProducts.push({
            id: recommendedProduct.id,
            status: this.recommendedProductsApplied['p' + index][i]
              ? 'APPLIED'
              : 'NOT_APPLIED',
          });
        }
      );

      const variables = {
        recommendedProducts: appliedProducts,
      };

      const mutation = gql`
        mutation updateRecommendedProductStatus($input: DataUpdateStatusInput) {
          updateRecommendedProductStatus(input: $input) {
            success
            message
          }
        }
      `;

      const {
        data: { updateRecommendedProductStatus },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
        })
        .catch(async () => {
          const dataToSave = {
            token: localStorage.getItem('token') || null,
            refresh: localStorage.getItem('refresh') || null,

            variables: { input: variables },
          };

          if (!this.isConnected) {
            // Guardo los datos localmente
            await db.recommendedProduct.put({
              id: this.selectedItem.support.recommendations[index].id,
              data: dataToSave,
            });

            // Actualizo la información en memoria
            appliedProducts.map((product, i) => {
              this.visits[this.selectedIndex].support.recommendations[
                index
              ].recommendedProducts[i].status = product.status;
            });

            // Registro el evento sync para que se ejecute cuando tenga conexión
            navigator.serviceWorker.getRegistration().then((reg) => {
              reg.sync.register('update-recommended-products');
            });

            this.$refs[`overlay${index}`][0].value = false;
            this.$store.commit('snackbar/setSnack', {
              message: 'Datos guardados correctamente',
              color: 'success',
            });

            return;
          }
        });

      this.initialize();

      this.$refs[`overlay${index}`][0].value = false;

      this.$store.commit('snackbar/setSnack', {
        message: updateRecommendedProductStatus.message,
        color: updateRecommendedProductStatus.success ? 'success' : 'error',
      });
    },

    async openVisitsDialog({ id }) {
      this.selectedIndex = this.visits.findIndex((item) => item.id == id);
      this.selectedItem = Object.assign({}, this.visits[this.selectedIndex]);

      this.visitObservation = this.selectedItem.description;

      this.recommendedProductsApplied = [];
      this.selectedItem.support.recommendations.map((recommendation, index) => {
        // Llenar el array de si han sido o no aplicados los productos recomendados
        const areApplied = [];
        recommendation.recommendedProducts.map((product) => {
          const isApplied = product.status == 'APPLIED' ? true : false;
          areApplied.push(isApplied);
        });

        if (areApplied.length > 0) {
          this.recommendedProductsApplied['p' + index] = areApplied;
        }
      });

      this.dialogVisits = true;
      this.overlay = true;

      this.overlay = false;
    },

    openDatasheet(item) {
      this.selectedIndex = this.visits.indexOf(item);
      this.selectedItem = Object.assign({}, item);

      this.activeTab = 0;

      this.dialog = true;
    },

    dateToHuman(date) {
      return dayjs(date).format('LL h:mm a');
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },
  },
};
</script>
