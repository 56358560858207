<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <personal-data-farmers v-if="userRole && userRole == 'FARMER'" />
        <personal-data-users v-if="userRole && userRole != 'FARMER'" />
      </v-col>
      <v-col cols="12" sm="6">
        <change-pass />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PersonalDataUsers from '@/components/profile/PersonalDataUsers.vue';
import PersonalDataFarmers from '@/components/profile/PersonalDataFarmers.vue';
import ChangePass from '@/components/profile/ChangePass.vue';

export default {
  name: 'Profile',

  components: {
    PersonalDataUsers,
    PersonalDataFarmers,
    ChangePass,
  },

  data: () => ({}),

  computed: {
    ...mapGetters({ userRole: 'userData/userRole' }),
  },
};
</script>
