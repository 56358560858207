export default {
  namespaced: true,
  name: 'network',
  state: {
    connection: {},
  },

  mutations: {
    setState(state, stateData) {
      state.connection = { ...stateData };
    },

    resetState(state) {
      Object.assign(state, {});
    },
  },

  getters: {
    isConnected: (state) => {
      return state.connection.connected;
    },
  },
};
