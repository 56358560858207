export default {
  namespaced: true,
  name: 'userData',
  state: {
    user: {},
  },

  mutations: {
    setData(state, userData) {
      state.user = { ...userData };
    },

    resetState(state) {
      Object.assign(state, {});
    },
  },

  getters: {
    userRole: (state) => {
      return state.user.role;
    },
  },
};
