import Vue from 'vue';
import Vuex from 'vuex';

import snackbar from './snackbar.js';
import userData from './userData.js';
import network from './network.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    snackbar,
    userData,
    network,
  },
});
