<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="tableCrops"
      :options.sync="options"
      :server-items-length="totalCrops"
      :items-per-page="12"
      :footer-props="{
        'disable-items-per-page': true,
        itemsPerPageOptions: [12],
      }"
      @page-count="pageCount = $event"
      no-data-text="No tiene cultivos registrados"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />

          <v-btn color="primary" fab x-small @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-dialog
              v-model="dialog"
              v-if="dialog"
              max-width="600px"
              persistent
              scrollable
            >
              <v-form @submit.prevent="submitForm">
                <v-card>
                  <v-card-title>
                    <span class="text-button grey--text text--darken-1">{{
                      formTitle
                    }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el producto"
                          >
                            <v-autocomplete
                              v-model="selectedItem.productId"
                              dense
                              eager
                              clearable
                              small-chips
                              hide-selected
                              label="Producto*"
                              :error-messages="errors"
                              :items="products"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-dialog
                            ref="dialogDate"
                            v-model="dateModal"
                            :return-value.sync="selectedItem.date"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                                name="la fecha"
                              >
                                <v-text-field
                                  v-model="formatInputDate"
                                  :value="formatInputDate"
                                  label="Fecha siembra*"
                                  clearable
                                  dense
                                  :error-messages="errors"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              v-model="selectedItem.date"
                              no-title
                              scrollable
                              color="primary"
                            >
                              <v-spacer />
                              <v-btn
                                text
                                color="grey darken-1"
                                @click="dateModal = false"
                              >
                                Cancelar
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  $refs.dialogDate.save(selectedItem.date)
                                "
                              >
                                Aceptar
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="la unidad de medida"
                          >
                            <v-select
                              v-model="selectedItem.measurementType"
                              :items="[
                                {
                                  text: 'Número de plantas',
                                  value: 'PLANTS_NUMBER',
                                },
                                { text: 'Hectáreas', value: 'HECTARES' },
                              ]"
                              dense
                              clearable
                              label="Unidad de medida*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|numeric"
                            name="la cantidad"
                          >
                            <v-text-field
                              v-model="selectedItem.qty"
                              dense
                              clearable
                              label="Cantidad*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>*Indica campo requerido</small>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />

                    <v-btn color="grey darken-1" text @click="close">
                      Cancelar
                    </v-btn>

                    <v-btn
                      type="submit"
                      color="green darken-2"
                      :disabled="invalid"
                      text
                    >
                      {{ formActionBtn }}
                    </v-btn>
                  </v-card-actions>

                  <v-overlay :value="overlay" absolute>
                    <v-progress-circular indeterminate size="48" />
                  </v-overlay>
                </v-card>
              </v-form>
            </v-dialog>
          </ValidationObserver>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>

      <template #footer.prepend>
        <v-spacer class="py-6" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import restrictedActionMixin from '@/mixins/restrictedActionMixin';

dayjs.locale('es');
dayjs.extend(localizedFormat);

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

export default {
  name: 'Crops',

  components: { ValidationProvider, ValidationObserver },

  async created() {
    const query = gql`
      query getProductsByFarmer {
        getProductsByFarmer {
          products {
            value: id
            text: name
          }
        }
      }
    `;

    const {
      data: { getProductsByFarmer },
    } = await this.$apollo
      .query({
        query,
        fetchPolicy: 'network-only',
      })
      .catch((error) => {
        this.restrictedAction(error);
        return;
      });

    this.products = getProductsByFarmer.products;
  },

  mixins: [restrictedActionMixin],

  data: () => ({
    overlay: false,

    dateModal: false,
    dialog: false,
    loading: false,
    options: {},
    totalCrops: 0,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Cultivo',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Cantidad Sembrada',
        sortable: false,
        value: 'qtyText',
      },
      {
        text: 'Fecha',
        sortable: false,
        value: 'humanDate',
      },
      { text: 'Acciones', sortable: false, value: 'actions', align: 'end' },
    ],
    products: [],
    crops: [],
    tableCrops: [],
    selectedIndex: -1,
    selectedItem: {},
    defaultItem: {},
  }),

  computed: {
    formTitle() {
      return this.selectedIndex === -1 ? 'Añadir cultivo' : 'Editar cultivo';
    },

    formActionBtn() {
      return this.selectedIndex === -1 ? 'Añadir cultivo' : 'Editar cultivo';
    },

    formatInputDate: {
      get() {
        return this.selectedItem.date
          ? dayjs(this.selectedItem.date).format('LL')
          : '';
      },

      set(value) {
        this.selectedItem.date = value;
      },
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    async initialize() {
      this.loading = true;
      const query = gql`
        query getCropsPaginated($input: GetCropsPaginatedInput!) {
          getCropsPaginated(input: $input) {
            crops {
              id
              date
              measurementType
              qty

              product {
                value: id
                text: name
              }
            }
            total
          }
        }
      `;

      const {
        data: { getCropsPaginated },
      } = await this.$apollo
        .query({
          query,
          variables: {
            input: {
              page: this.options.page,
              perPage: this.options.itemsPerPage,
            },
          },
          fetchPolicy: 'network-only',
        })
        .catch((error) => {
          this.loading = false;

          this.restrictedAction(error);
          return;
        });

      this.crops = getCropsPaginated.crops;
      this.totalCrops = getCropsPaginated.total;

      const tableCrops = [];
      getCropsPaginated.crops.map((crop) => {
        tableCrops.push({
          id: crop.id,
          name: crop.product.text,
          humanDate: dayjs(crop.date).format('LL'),
          qtyText: `${crop.qty} ${
            crop.measurementType == 'HECTARES' ? 'Hectáreas' : 'Plantas'
          }`,
        });
      });

      this.tableCrops = tableCrops;

      this.loading = false;
    },

    editItem({ id }) {
      // TODO: Aplicar esta manera en todas las tablas en los que se requiera modificar algún dato para mostrarlo, con un array independiente
      this.selectedIndex = this.crops.findIndex((item) => item.id == id);
      this.selectedItem = Object.assign({}, this.crops[this.selectedIndex]);
      // Asigno directamente el productId para luego eliminar la propiedad product
      this.selectedItem.productId = this.selectedItem.product.value;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    async submitForm() {
      this.overlay = true;

      let mutation;
      const variables = this.selectedItem;
      variables.qty = +variables.qty;

      if (this.selectedIndex > -1) {
        mutation = gql`
          mutation editCrop($input: DataCropInput) {
            editCrop(input: $input) {
              success
              message
            }
          }
        `;

        delete variables.product;
      } else {
        mutation = gql`
          mutation addCrop($input: DataCropInput) {
            addCrop(input: $input) {
              success
              message
            }
          }
        `;
      }

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      let isSuccess, message;
      if (this.selectedIndex > -1) {
        isSuccess = data.editCrop.success;
        message = data.editCrop.message;
      } else {
        isSuccess = data.addCrop.success;
        message = data.addCrop.message;
      }

      if (isSuccess) {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'success',
        });

        this.initialize();

        this.close();
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'error',
        });
      }

      this.overlay = false;
    },
  },
};
</script>
