<template>
  <div>
    <v-row class="mt-0">
      <v-col cols="12" class="d-flex">
        <v-btn
          v-if="userRole == 'AGRONOMIST'"
          class="ml-auto"
          small
          outlined
          color="primary"
          :disabled="status == 'INACTIVE'"
          @click="dialogScheduleVisit = true"
        >
          Agendar visita
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="visits.length == 0">No se han agendado visitas.</div>
    <v-row class="mt-0" v-for="(visit, index) in visits" :key="index">
      <v-col cols="12" sm="6">
        <v-text-field :value="visit.date" label="Fecha visita" dense readonly />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          :value="`${visit.technician.name} ${visit.technician.lastname}`"
          label="Nombre técnico"
          dense
          readonly
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          :value="visit.description"
          dense
          auto-grow
          rows="1"
          label="Observaciones"
          readonly
        />
      </v-col>
      <v-col cols="12" v-if="index + 1 < visits.length" class="my-4 pt-0">
        <v-divider />
      </v-col>
    </v-row>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-dialog
        v-if="userRole == 'AGRONOMIST' && dialogScheduleVisit"
        v-model="dialogScheduleVisit"
        max-width="600px"
        persistent
      >
        <v-form @submit.prevent="submitForm">
          <v-card>
            <v-card-title>
              <span class="text-button grey--text text--darken-1">
                Agendar visita
              </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="el técnico"
                    >
                      <v-autocomplete
                        v-model="formData.userId"
                        dense
                        eager
                        clearable
                        small-chips
                        hide-selected
                        label="Técnico*"
                        :error-messages="errors"
                        :items="technicianList"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-dialog
                      ref="dialogDate"
                      v-model="dateModal"
                      :return-value.sync="formData.date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          name="la fecha"
                        >
                          <v-text-field
                            v-model="formatInputDate"
                            :value="formatInputDate"
                            label="Fecha visita*"
                            clearable
                            dense
                            :error-messages="errors"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          />
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="formData.date"
                        no-title
                        scrollable
                        :min="today"
                        color="primary"
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="grey darken-1"
                          @click="dateModal = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogDate.save(formData.date)"
                        >
                          Aceptar
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-dialog
                      ref="dialogHour"
                      v-model="hourModal"
                      :return-value.sync="formData.hour"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          name="la hora"
                        >
                          <v-text-field
                            v-model="formatInputHour"
                            :value="formatInputHour"
                            label="Hora visita*"
                            clearable
                            dense
                            :error-messages="errors"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </ValidationProvider>
                      </template>
                      <v-time-picker
                        v-if="hourModal"
                        v-model="formData.hour"
                        full-width
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="grey darken-1"
                          @click="hourModal = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogHour.save(formData.hour)"
                        >
                          Aceptar
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-container>
              <small>*Indica campo requerido</small>
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="grey darken-1"
                text
                @click="closeScheduleVisitDialog"
              >
                Cancelar
              </v-btn>

              <v-btn
                type="submit"
                color="green darken-2"
                :disabled="invalid"
                text
              >
                Agendar visita
              </v-btn>
            </v-card-actions>

            <v-overlay :value="overlayScheduleVisit" absolute>
              <v-progress-circular indeterminate size="48" />
            </v-overlay>
          </v-card>
        </v-form>
      </v-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import { mapGetters } from 'vuex';
import gql from 'graphql-tag';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('es');
dayjs.extend(localizedFormat);

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

export default {
  name: 'SupportVisitsTab',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  props: ['supportId', 'farmerId', 'status'],

  async created() {
    this.initialize();
  },

  mixins: [restrictedActionMixin],

  data: () => ({
    visits: [],

    formData: {},
    technicianList: [],

    dialogScheduleVisit: false,
    overlayScheduleVisit: false,

    dateModal: false,
    hourModal: false,

    today: dayjs.tz().format(),
  }),

  methods: {
    closeScheduleVisitDialog() {
      this.dialogScheduleVisit = false;
    },

    async initialize() {
      this.$emit('loading', true);

      const query = gql`
        query GetVisits($input: GetVisitsInput) {
          getVisits(input: $input) {
            visits {
              date
              hour
              description

              technician: user {
                name
                lastname
              }
            }
          }
        }
      `;

      const {
        data: { getVisits },
      } = await this.$apollo
        .query({
          query,
          variables: { input: { supportId: this.supportId } },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.$emit('loading', false);

          this.restrictedAction(error);
          return;
        });

      this.visits = getVisits.visits.map((visit) => {
        visit.date = dayjs(`${visit.date}T${visit.hour}`).format('LL h:mm a');

        return visit;
      });

      const queryTechnicians = gql`
        query GetTechnicians {
          getTechnicians {
            technicians {
              id
              name
              lastname
            }
          }
        }
      `;

      const {
        data: { getTechnicians },
      } = await this.$apollo.query({
        query: queryTechnicians,
        fetchPolicy: 'network-only',
      });

      this.technicianList = [];
      getTechnicians.technicians.forEach((technician) => {
        this.technicianList.push({
          value: technician.id,
          text: `${technician.name} ${technician.lastname}`,
        });
      });

      // this.technicianList = getTechnicians.technicians;

      this.$emit('loading', false);
    },

    async submitForm() {
      this.overlayScheduleVisit = true;

      const mutation = gql`
        mutation addVisit($input: DataVisitInput) {
          addVisit(input: $input) {
            success
            message
          }
        }
      `;

      const variables = this.formData;

      variables.hour = variables.hour + ':00';
      variables.supportId = this.supportId;
      variables.farmerId = this.farmerId;

      const {
        data: { addVisit },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
        })
        .catch((error) => {
          this.overlayScheduleVisit = false;

          this.restrictedAction(error);

          return;
        });

      this.$store.commit('snackbar/setSnack', {
        message: addVisit.message,
        color: addVisit.success ? 'success' : 'error',
      });

      this.overlayScheduleVisit = false;
      this.dialogScheduleVisit = false;
      this.formData = {};

      this.initialize();
    },
  },

  computed: {
    ...mapGetters({ userRole: 'userData/userRole' }),

    formatInputDate: {
      get() {
        return this.formData.date ? dayjs(this.formData.date).format('LL') : '';
      },

      set(value) {
        this.formData.date = value;
      },
    },

    formatInputHour: {
      get() {
        return this.formData.hour
          ? dayjs('1700-01-01T' + this.formData.hour).format('h:mm A')
          : '';
      },

      set(value) {
        this.formData.hour = value;
      },
    },
  },
};
</script>

<style></style>
