<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="tableLocations"
      :options.sync="options"
      :server-items-length="totalLocations"
      :items-per-page="12"
      :footer-props="{
        'disable-items-per-page': true,
        itemsPerPageOptions: [12],
      }"
      @page-count="pageCount = $event"
      no-data-text="No hay veredas registradas"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />

          <v-btn color="primary" fab x-small @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-dialog
              v-model="dialog"
              v-if="dialog"
              max-width="600px"
              persistent
              scrollable
            >
              <v-form @submit.prevent="submitForm">
                <v-card>
                  <v-card-title>
                    <span class="text-button grey--text text--darken-1">{{
                      formTitle
                    }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el nombre de la vereda"
                          >
                            <v-text-field
                              v-model="selectedItem.name"
                              dense
                              clearable
                              label="Nombre vereda*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el municipio"
                          >
                            <v-autocomplete
                              v-model="selectedItem.townId"
                              dense
                              clearable
                              label="Municipio"
                              :error-messages="errors"
                              :items="towns"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>*Indica campo requerido</small>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />

                    <v-btn color="grey darken-1" text @click="close">
                      Cancelar
                    </v-btn>

                    <v-btn
                      type="submit"
                      color="green darken-2"
                      :disabled="invalid"
                      text
                    >
                      {{ formActionBtn }}
                    </v-btn>
                  </v-card-actions>

                  <v-overlay :value="overlay" absolute>
                    <v-progress-circular indeterminate size="48" />
                  </v-overlay>
                </v-card>
              </v-form>
            </v-dialog>
          </ValidationObserver>

          <v-dialog
            v-model="dialogDelete"
            v-if="dialogDelete"
            max-width="600px"
            persistent
          >
            <v-card>
              <v-card-title
                class="grey--text text--darken-1 font-weight-regular text-subtitle-1"
              >
                ¿Está seguro que desea eliminar la vereda
                <b>&nbsp;{{ selectedItem.name }}</b
                >?
              </v-card-title>

              <v-card-text>
                La información no podrá ser recuperada.
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="grey darken-1" text @click="closeDelete">
                  Cancelar
                </v-btn>
                <v-btn color="red darken-1" text @click="deleteItemConfirm">
                  Eliminar vereda
                </v-btn>
              </v-card-actions>

              <v-overlay :value="overlay" absolute>
                <v-progress-circular indeterminate size="48" />
              </v-overlay>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template #footer.prepend>
        <v-spacer class="py-6" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import gql from 'graphql-tag';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

export default {
  components: { ValidationProvider, ValidationObserver },

  async created() {
    const query = gql`
      query GetAllTowns {
        getAllTowns {
          value: id
          text: name
        }
      }
    `;

    const {
      data: { getAllTowns },
    } = await this.$apollo
      .query({
        query,
        fetchPolicy: 'network-only',
      })
      .catch((error) => {
        this.restrictedAction(error);
        return;
      });

    this.towns = getAllTowns;
  },

  mixins: [restrictedActionMixin],

  data: () => ({
    overlay: false,

    dialog: false,
    dialogDelete: false,
    loading: false,
    options: {},
    totalLocations: 0,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Vereda',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Municipio', sortable: false, value: 'townName' },
      { text: 'Acciones', sortable: false, value: 'actions', align: 'end' },
    ],
    locations: [],
    tableLocations: [],
    selectedIndex: -1,
    selectedItem: {},
    defaultItem: {},
    towns: [],
  }),

  computed: {
    formTitle() {
      return this.selectedIndex === -1 ? 'Crear vereda' : 'Editar vereda';
    },

    formActionBtn() {
      return this.selectedIndex === -1 ? 'Crear vereda' : 'Editar vereda';
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async initialize() {
      this.loading = true;
      const query = gql`
        query getLocations($input: GetLocationsInput!) {
          getLocations(input: $input) {
            locations {
              id
              name

              town {
                text: name
                value: id
              }
            }
            total
          }
        }
      `;

      const {
        data: { getLocations },
      } = await this.$apollo
        .query({
          query,
          variables: {
            input: {
              page: this.options.page,
              perPage: this.options.itemsPerPage,
            },
          },
          fetchPolicy: 'network-only',
        })
        .catch((error) => {
          this.loading = false;

          this.restrictedAction(error);
          return;
        });

      this.locations = getLocations.locations;
      this.totalLocations = getLocations.total;

      const tableLocations = [];
      getLocations.locations.map((location) => {
        tableLocations.push({
          id: location.id,
          name: location.name,
          townName: location.town.text,
        });
      });

      this.tableLocations = tableLocations;

      this.loading = false;
    },

    editItem({ id }) {
      this.selectedIndex = this.locations.findIndex((item) => item.id == id);
      this.selectedItem = Object.assign({}, this.locations[this.selectedIndex]);
      // Asigno directamente el townId para luego eliminar la propiedad town
      this.selectedItem.townId = this.selectedItem.town.value;
      this.dialog = true;
    },

    deleteItem(item) {
      this.selectedIndex = this.locations.indexOf(item);
      this.selectedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.overlay = true;

      const mutation = gql`
        mutation deleteLocation($input: DeleteLocationInput) {
          deleteLocation(input: $input) {
            success
            message
          }
        }
      `;

      const {
        data: { deleteLocation },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: {
            input: {
              id: this.selectedItem.id,
            },
          },
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      this.overlay = false;

      if (deleteLocation.success) {
        this.initialize();
        this.closeDelete();
      }

      this.$store.commit('snackbar/setSnack', {
        message: deleteLocation.message,
        color: deleteLocation.success ? 'success' : 'error',
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    async submitForm() {
      this.overlay = true;

      let mutation;
      const variables = this.selectedItem;

      if (this.selectedIndex > -1) {
        mutation = gql`
          mutation editLocation($input: DataLocationInput) {
            editLocation(input: $input) {
              success
              message
            }
          }
        `;

        delete variables.town;
      } else {
        mutation = gql`
          mutation addLocation($input: DataLocationInput) {
            addLocation(input: $input) {
              success
              message
            }
          }
        `;
      }

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      let isSuccess, message;
      if (this.selectedIndex > -1) {
        isSuccess = data.editLocation.success;
        message = data.editLocation.message;
      } else {
        isSuccess = data.addLocation.success;
        message = data.addLocation.message;
      }

      if (isSuccess) {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'success',
        });

        this.initialize(); // TODO: revisar si en lugar de esto uso el refetch query

        this.close();
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'error',
        });
      }

      this.overlay = false;
    },
  },
};
</script>
