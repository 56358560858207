<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="tableSupports"
      :options.sync="options"
      :server-items-length="totalSupports"
      :items-per-page="12"
      :footer-props="{
        'disable-items-per-page': true,
        itemsPerPageOptions: [12],
      }"
      @page-count="pageCount = $event"
      no-data-text="No hay asistencias registradas"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          v-if="dialog"
          max-width="600px"
          persistent
          scrollable
        >
          <!-- <v-form @submit.prevent="submitForm"> -->
          <v-card>
            <v-card-title>
              <v-tabs
                v-model="activeTab"
                background-color="transparent"
                color="primary"
                show-arrows
                grow
              >
                <v-tab>Asistencia</v-tab>
                <v-tab>Visitas</v-tab>
                <v-tab>Mensajes</v-tab>
              </v-tabs>
            </v-card-title>

            <v-card-text id="divCardText">
              <v-tabs-items v-model="activeTab">
                <v-tab-item
                  v-for="(tabComponent, index) in tabsComponents"
                  :key="index"
                >
                  <component
                    :is="tabComponent.component"
                    :supportId="selectedItem.id"
                    :farmerId="selectedItem.farmerId"
                    :status="selectedItem.status"
                    @loading="overlay = $event"
                    @closeDialog="close"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn color="grey darken-1" text @click="close"> Cerrar </v-btn>
            </v-card-actions>

            <v-overlay :value="overlay" absolute>
              <v-progress-circular indeterminate size="48" />
            </v-overlay>
          </v-card>
          <!-- </v-form> -->
        </v-dialog>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small @click="openDatasheet(item)"> mdi-eye </v-icon>
      </template>

      <template #footer.prepend>
        <v-spacer class="py-6" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('es');
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.tz.setDefault('America/Bogota');

import gql from 'graphql-tag';

import SupportSheetTab from '@/components/support/supportTabs/SupportSheetTab.vue';
import SupportVisitsTab from '@/components/support/supportTabs/SupportVisitsTab.vue';
import SupportMessageTab from '@/components/support/supportTabs/SupportMessageTab.vue';

export default {
  name: 'SupportAgronomistView',

  components: {
    SupportSheetTab,
    SupportVisitsTab,
    SupportMessageTab,
  },

  data: () => ({
    overlay: false,

    dialog: false,
    search: '',
    loading: false,
    options: {},
    totalSupports: 0,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Fecha',
        align: 'start',
        sortable: false,
        value: 'humanDate',
      },
      { text: 'Productor', sortable: false, value: 'farmerName' },
      { text: 'Estado', sortable: false, value: 'statusText' },
      { text: 'Acción', sortable: false, value: 'actions', align: 'end' },
    ],
    supports: [],
    tableSupports: [],
    selectedIndex: -1,
    selectedItem: {},
    defaultItem: {},

    tabsComponents: [
      { component: SupportSheetTab },
      { component: SupportVisitsTab },
      { component: SupportMessageTab },
    ],
    activeTab: 0,
  }),

  computed: {},

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.selectedItem = Object.assign({}, this.defaultItem);
  },

  methods: {
    async initialize() {
      this.loading = true;
      const query = gql`
        query getSupportsPaginated($input: GetSupportsPaginatedInput!) {
          getSupportsPaginated(input: $input) {
            supports {
              id
              date
              status

              farmer {
                id
                name
                lastname
              }
            }

            total
          }
        }
      `;

      const {
        data: { getSupportsPaginated },
      } = await this.$apollo.query({
        query,
        variables: {
          input: {
            page: this.options.page,
            perPage: this.options.itemsPerPage,
          },
        },
        fetchPolicy: 'network-only',
      });

      this.supports = getSupportsPaginated.supports;
      this.totalSupports = getSupportsPaginated.total;

      const tableSupports = [];
      getSupportsPaginated.supports.map((support) => {
        tableSupports.push({
          id: support.id,
          humanDate: dayjs(support.date).format('LL'),
          farmerName: `${support.farmer.name} ${support.farmer.lastname}`,
          farmerId: support.farmer.id,
          statusText: `${support.status == 'ACTIVE' ? 'Activo' : 'Inactivo'}`,
          status: support.status,
        });
      });

      this.tableSupports = tableSupports;

      this.loading = false;
    },

    openDatasheet(item) {
      this.selectedIndex = this.supports.indexOf(item);
      this.selectedItem = Object.assign({}, item);

      this.activeTab = 0;

      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    /*
    async submitForm() {
      this.overlay = true;

      let mutation;
      const variables = this.selectedItem;
      delete variables.lastAccess;
      delete variables.humanLastAccess;
      delete variables.fullname;
      delete variables.roleText;

      if (this.selectedIndex > -1) {
        // console.log('Se va editar', this.selectedItem);

        mutation = gql`
          mutation editTechnician($input: DataTechnicianInput) {
            editTechnician(input: $input) {
              success
              message
            }
          }
        `;
      } else {
        // console.log('Se va añadir', this.selectedItem);

        mutation = gql`
          mutation addTechnician($input: DataTechnicianInput) {
            addTechnician(input: $input) {
              success
              message
            }
          }
        `;
      }

      // console.log(variables);

      const { data } = await this.$apollo.mutate({
        mutation,
        variables: { input: variables },
        fetchPolicy: 'no-cache',
      });

      // console.log(this.selectedIndex);
      // Hice el if porque con oeperador ternario falla
      let isSuccess, message;
      if (this.selectedIndex > -1) {
        isSuccess = data.editTechnician.success;
        message = data.editTechnician.message;
      } else {
        isSuccess = data.addTechnician.success;
        message = data.addTechnician.message;
      }

      if (isSuccess) {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'success',
        });

        this.initialize(); 

        this.close();
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'error',
        });
      }

      this.overlay = false;
    },*/
  },
};
</script>
