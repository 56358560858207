<template>
  <div>
    <products-farmer-view v-if="userRole && userRole == 'FARMER'" />
    <products-admin-view v-if="userRole && userRole == 'ADMIN'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ProductsAdminView from '@/components/products/ProductsAdminView.vue';
import ProductsFarmerView from '@/components/products/ProductsFarmerView.vue';

export default {
  name: 'Products',

  components: {
    ProductsAdminView,
    ProductsFarmerView,
  },

  data: () => ({}),

  computed: {
    ...mapGetters({ userRole: 'userData/userRole' }),
  },
};
</script>
