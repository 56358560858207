<template>
  <div
    class="bubble"
    :class="
      message.by == userRole ||
      (message.by == 'AGRONOMIST' && userRole != 'FARMER')
        ? 'right'
        : null
    "
  >
    <div
      class="caption grey--text text--lighten-1"
      style="font-size: 10px !important"
    >
      {{ message.date }}
    </div>
    {{ message.msg }}
  </div>
</template>

<script>
export default {
  name: 'ChatBubble',

  props: ['message', 'userRole'],

  data: () => ({}),
};
</script>

<style lang="sass" scoped>
.bubble
  background: #ebebeb
  padding: .1rem .6rem
  border-radius: 3px
  margin-top: 4px
  max-width: 90%
  width: fit-content

.right
  margin-left: auto
  background: #e6e7f5

  .caption
    text-align: right
</style>
