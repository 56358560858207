<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="tableFarmers"
      :options.sync="options"
      :server-items-length="totalFarmers"
      :items-per-page="12"
      :footer-props="{
        'disable-items-per-page': true,
        itemsPerPageOptions: [12],
      }"
      @page-count="pageCount = $event"
      no-data-text="No hay productores registrados"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />
          <v-btn fab x-small raised @click="stats = true">
            <v-icon>mdi-chart-box-outline</v-icon>
          </v-btn>

          <v-divider class="mx-4" vertical inset />

          <v-btn color="primary" fab x-small @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-dialog
            v-model="stats"
            v-if="stats"
            max-width="600px"
            persistent
            scrollable
          >
            <v-card>
              <v-card-title>
                <span class="text-button grey--text text--darken-1">
                  Productores Asociados
                </span>
              </v-card-title>
              <v-card-text>
                <span>{{ totalFarmers }} productores en total</span>

                <v-progress-linear
                  :value="menPercent"
                  color="primary"
                  height="25"
                  rounded
                >
                  <template v-slot:default="{ value }">
                    {{ totalMen }} hombres ({{ value }}%)
                  </template>
                </v-progress-linear>

                <v-progress-linear
                  class="mt-2 mb-2"
                  :value="womenPercent"
                  color="primary"
                  height="25"
                  rounded
                >
                  <template v-slot:default="{ value }">
                    {{ totalWomen }} mujeres ({{ value }}%)
                  </template>
                </v-progress-linear>
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn color="grey darken-1" text @click="stats = false">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-dialog
              v-model="dialog"
              v-if="dialog"
              max-width="600px"
              persistent
              scrollable
            >
              <v-form @submit.prevent="submitForm">
                <v-card>
                  <v-card-title>
                    <span class="text-button grey--text text--darken-1">
                      {{ formTitle }}
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el nombre del productor"
                          >
                            <v-text-field
                              v-model="selectedItem.name"
                              dense
                              clearable
                              label="Nombre/s productor*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el apellido del productor"
                          >
                            <v-text-field
                              v-model="selectedItem.lastname"
                              dense
                              clearable
                              label="Apellidos/s productor*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el tipo de documento"
                          >
                            <v-select
                              v-model="selectedItem.documentType"
                              :items="[
                                { text: 'Cédula Ciudadanía', value: 'CC' },
                                { text: 'NIT', value: 'NIT' },
                                { text: 'Cédula Extranjería', value: 'CE' },
                              ]"
                              dense
                              clearable
                              label="Tipo de documento*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|numeric|min:7"
                            name="el número de documento"
                          >
                            <v-text-field
                              v-model="selectedItem.documentNumber"
                              dense
                              clearable
                              label="Número documento*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el género"
                          >
                            <v-select
                              v-model="selectedItem.gender"
                              :items="[
                                { text: 'Masculino', value: 'MALE' },
                                { text: 'Femenino', value: 'FEMALE' },
                              ]"
                              dense
                              clearable
                              label="Género*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="numeric|digits:10|required"
                            name="el celular"
                          >
                            <v-text-field
                              v-model="selectedItem.mobile"
                              dense
                              clearable
                              label="Celular*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="email|required"
                            name="el correo"
                          >
                            <v-text-field
                              v-model="selectedItem.email"
                              dense
                              clearable
                              label="Correo electrónico*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el municipio"
                          >
                            <v-autocomplete
                              v-model="selectedItem.townId"
                              :items="towns"
                              :loading="isLoadingTowns"
                              :disabled="isDisabledTowns"
                              dense
                              clearable
                              label="Municipio*"
                              :error-messages="errors"
                              required
                              @change="townAutoCompleteChange"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="la vereda"
                          >
                            <v-autocomplete
                              v-model="selectedItem.locationId"
                              :items="locations"
                              :loading="isLoadingLocations"
                              :disabled="isDisabledLocations"
                              dense
                              clearable
                              label="Vereda*"
                              :error-messages="errors"
                              required
                              @change="locationAutoCompleteChange"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="la asociación"
                          >
                            <v-autocomplete
                              v-model="selectedItem.associationId"
                              :items="associations"
                              :loading="isLoadingAssociations"
                              :disabled="isDisabledAssociations"
                              dense
                              clearable
                              label="Asociación*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules=""
                            name="el nombre de la finca"
                          >
                            <v-text-field
                              v-model="selectedItem.farmName"
                              dense
                              clearable
                              label="Nombre finca"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el estado"
                          >
                            <v-select
                              v-model="selectedItem.status"
                              :items="[
                                { text: 'Activo', value: 'ACTIVE' },
                                { text: 'Inactivo', value: 'INACTIVE' },
                              ]"
                              dense
                              clearable
                              label="Estado*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <!--
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el producto"
                          >
                            <v-autocomplete
                              v-model="selectedItem.products"
                              dense
                              clearable
                              multiple
                              small-chips
                              deletable-chips
                              hide-selected
                              label="Producto/s"
                              :error-messages="errors"
                              :items="products"
                            />
                          </ValidationProvider>
                        </v-col>
                        -->
                      </v-row>
                    </v-container>
                    <small>*Indica campo requerido</small>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />

                    <v-btn color="grey darken-1" text @click="close">
                      Cancelar
                    </v-btn>

                    <v-btn
                      type="submit"
                      color="green darken-2"
                      :disabled="invalid"
                      text
                    >
                      {{ formActionBtn }}
                    </v-btn>
                  </v-card-actions>

                  <v-overlay :value="overlay" absolute>
                    <v-progress-circular indeterminate size="48" />
                  </v-overlay>
                </v-card>
              </v-form>
            </v-dialog>
          </ValidationObserver>

          <v-dialog
            v-model="dialogDelete"
            v-if="dialogDelete"
            max-width="600px"
            persistent
          >
            <v-card>
              <v-card-title
                class="grey--text text--darken-1 font-weight-regular text-subtitle-1"
              >
                ¿Está seguro que desea eliminar el productor
                <b>&nbsp;{{ selectedItem.fullName }}</b
                >?
              </v-card-title>

              <v-card-text>
                La información no podrá ser recuperada.
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="grey darken-1" text @click="closeDelete">
                  Cancelar
                </v-btn>
                <v-btn color="red darken-1" text @click="deleteItemConfirm">
                  Eliminar productor
                </v-btn>
              </v-card-actions>

              <v-overlay :value="overlay" absolute>
                <v-progress-circular indeterminate size="48" />
              </v-overlay>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template #footer.prepend>
        <v-spacer class="py-6" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import { mapGetters } from 'vuex';

import gql from 'graphql-tag';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

export default {
  components: { ValidationProvider, ValidationObserver },

  async mounted() {
    /*
    const queryLocations = gql`
      query GetAllLocations {
        getAllLocations {
          value: id
          text: name
        }
      }
    `;

    const {
      data: { getAllLocations },
    } = await this.$apollo.query({
      query: queryLocations,
      fetchPolicy: 'network-only',
    });

    this.locations = getAllLocations;

    const queryAssociations = gql`
      query GetAllAssociations {
        getAllAssociations {
          value: id
          text: name
        }
      }
    `;

    const {
      data: { getAllAssociations },
    } = await this.$apollo.query({
      query: queryAssociations,
      fetchPolicy: 'network-only',
    });

    this.associations = getAllAssociations;
    */

    this.isLoadingTowns = true;

    const query = gql`
      query GetAllTowns {
        getAllTowns {
          value: id
          text: name
        }
      }
    `;

    const {
      data: { getAllTowns },
    } = await this.$apollo
      .query({
        query,
        fetchPolicy: 'network-only',
      })
      .catch((error) => {
        this.isLoadingTowns = false;

        this.restrictedAction(error);
        return;
      });

    this.towns = getAllTowns;

    this.isLoadingTowns = false;
    this.isDisabledTowns = false;

    const queryCrops = gql`
      query GetAllProducts {
        getAllProducts {
          value: id
          text: name
        }
      }
    `;

    const {
      data: { getAllProducts },
    } = await this.$apollo.query({
      query: queryCrops,
      fetchPolicy: 'network-only',
    });

    this.products = getAllProducts;
  },

  mixins: [restrictedActionMixin],

  data: () => ({
    overlay: false,

    stats: false,
    totalMen: 0,
    menPercent: 0,
    totalWomen: 0,
    womenPercent: 0,

    dialog: false,
    dialogDelete: false,
    loading: false,
    options: {},
    totalFarmers: 0,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Productor',
        align: 'start',
        sortable: false,
        value: 'fullName',
      },
      { text: 'Asociación', sortable: false, value: 'assocName' },
      { text: 'Acciones', sortable: false, value: 'actions', align: 'end' },
    ],
    selectedIndex: -1,
    selectedItem: {},
    defaultItem: {
      documentType: 'CC',
      gender: 'MALE',
      status: 'ACTIVE',
    },
    isDisabledTowns: true,
    isLoadingTowns: false,
    isDisabledLocations: true,
    isLoadingLocations: false,
    isDisabledAssociations: true,
    isLoadingAssociations: false,
    towns: [],
    associations: [],
    locations: [],

    farmers: [],
    tableFarmers: [],
    products: [],
  }),

  computed: {
    formTitle() {
      return this.selectedIndex === -1
        ? 'Agregar productor'
        : 'Editar productor';
    },

    formActionBtn() {
      return this.selectedIndex === -1
        ? 'Agregar productor'
        : 'Editar productor';
    },

    ...mapGetters({
      isConnected: 'network/isConnected',
    }),
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.selectedItem = Object.assign({}, this.defaultItem);
  },

  methods: {
    async initialize() {
      this.loading = true;
      const query = gql`
        query getFarmers($input: GetFarmersInput!) {
          getFarmers(input: $input) {
            farmers {
              id
              name
              lastname
              documentType
              documentNumber
              gender
              mobile
              email
              farmName
              status

              townId
              locationId
              associationId

              association {
                name
              }
            }

            totalFarmers
            totalMen
            totalWomen
          }
        }
      `;

      const {
        data: { getFarmers },
      } = await this.$apollo
        .query({
          query,
          variables: {
            input: {
              page: this.options.page,
              perPage: this.options.itemsPerPage,
            },
          },
          fetchPolicy: 'network-only',
        })
        .catch((error) => {
          this.loading = false;

          this.restrictedAction(error);
          return;
        });

      this.farmers = getFarmers.farmers;
      this.totalFarmers = getFarmers.totalFarmers;
      this.totalWomen = getFarmers.totalWomen;
      this.totalMen = getFarmers.totalMen;
      this.womenPercent = Math.round(
        (this.totalWomen / this.totalFarmers) * 100
      );
      this.menPercent = Math.round((this.totalMen / this.totalFarmers) * 100);

      /*
      // Extraigo los ids de los productos para preseleccionarlos al editar
      this.farmers = this.farmers.map((farmer) => {
        // Lo condcioné debido a que al darle editar con los datos ya cargados, no se realiza la petición al servidor y devuelve undefined
        farmer.products = farmer.products.map((product) =>
          product.id ? product.id : product
        );

        return farmer;
      });
      */

      const tableFarmers = [];
      getFarmers.farmers.map((farmer) => {
        if (!farmer.associationId) {
          farmer.associationId = 'not_associated';
          farmer.association = { name: 'No asociado' };
        }
        tableFarmers.push({
          id: farmer.id,
          fullName: `${farmer.name} ${farmer.lastname}`,
          assocName: farmer.association.name,
        });
      });

      this.tableFarmers = tableFarmers;

      this.loading = false;
    },

    editItem({ id }) {
      if (!this.isConnected) {
        this.$store.commit('snackbar/setSnack', {
          message: 'Requiere conexión a internet',
          color: 'warning',
        });

        return;
      }

      this.selectedIndex = this.farmers.findIndex((item) => item.id == id);
      this.selectedItem = Object.assign({}, this.farmers[this.selectedIndex]);

      this.townAutoCompleteChange(false);
      this.locationAutoCompleteChange();

      this.dialog = true;
    },

    deleteItem(item) {
      this.selectedIndex = this.farmers.indexOf(item);
      this.selectedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.overlay = true;

      const mutation = gql`
        mutation deleteFarmer($input: DeleteFarmerInput) {
          deleteFarmer(input: $input) {
            success
            message
          }
        }
      `;

      const {
        data: { deleteFarmer },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: {
            input: {
              id: this.selectedItem.id,
            },
          },
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      this.overlay = false;

      if (deleteFarmer.success) {
        this.initialize();
        this.closeDelete();
      }

      this.$store.commit('snackbar/setSnack', {
        message: deleteFarmer.message,
        color: deleteFarmer.success ? 'success' : 'error',
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
        this.isDisabledAssociations = true;
        this.isDisabledLocations = true;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    async townAutoCompleteChange(autoCall = true) {
      if (!this.selectedItem.townId) {
        this.selectedItem.locationId = '';
        this.locations = [];
        this.isDisabledLocations = true;

        this.clearAssociationSelect();

        this.$refs.observer.reset();

        return;
      }

      this.isLoadingLocations = true;

      const query = gql`
        query getAllLocationsByTown($input: GetAllLocationsByTownInput) {
          getAllLocationsByTown(input: $input) {
            value: id
            text: name
          }
        }
      `;

      const {
        data: { getAllLocationsByTown },
      } = await this.$apollo
        .query({
          query,
          variables: {
            input: {
              townId: this.selectedItem.townId,
            },
          },
          fetchPolicy: 'network-only',
        })
        .catch((error) => {
          this.isLoadingLocations = false;

          this.restrictedAction(error);
          return;
        });

      this.locations = getAllLocationsByTown;

      this.isLoadingLocations = false;
      this.isDisabledLocations = false;

      if (autoCall) {
        this.clearAssociationSelect();
      }
    },

    async locationAutoCompleteChange() {
      if (!this.selectedItem.locationId) {
        this.selectedItem.associationId = '';
        this.associations = [];
        this.isDisabledAssociations = true;

        this.$refs.observer.reset();

        return;
      }

      this.isLoadingAssociations = true;

      const query = gql`
        query getAllAssociationsByTown($input: getAllAssociationsByTownInput) {
          getAllAssociationsByTown(input: $input) {
            value: id
            text: name
          }
        }
      `;

      const {
        data: { getAllAssociationsByTown },
      } = await this.$apollo
        .query({
          query,
          variables: {
            input: {
              townId: this.selectedItem.townId,
            },
          },
          fetchPolicy: 'network-only',
        })
        .catch((error) => {
          this.isLoadingAssociations = false;

          this.restrictedAction(error);
          return;
        });

      this.associations = getAllAssociationsByTown;
      this.associations.push({ text: 'No asociado', value: 'not_associated' });

      this.isLoadingAssociations = false;
      this.isDisabledAssociations = false;
    },

    clearAssociationSelect() {
      this.selectedItem.associationId = '';
      this.associations = [];
      this.isDisabledAssociations = true;
    },

    async submitForm() {
      this.overlay = true;

      let mutation;
      const variables = this.selectedItem;

      delete variables.association;

      if (variables.associationId == 'not_associated') {
        delete variables.associationId;
      }

      if (this.selectedIndex > -1) {
        mutation = gql`
          mutation editFarmer($input: DataFarmerInput) {
            editFarmer(input: $input) {
              success
              message
            }
          }
        `;
      } else {
        mutation = gql`
          mutation addFarmer($input: DataFarmerInput) {
            addFarmer(input: $input) {
              success
              message
            }
          }
        `;
      }

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      let isSuccess, message;
      if (this.selectedIndex > -1) {
        isSuccess = data.editFarmer.success;
        message = data.editFarmer.message;
      } else {
        isSuccess = data.addFarmer.success;
        message = data.addFarmer.message;
      }

      if (isSuccess) {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'success',
        });

        this.initialize(); // TODO: revisar si en lugar de esto uso el refetch query

        this.close();
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'error',
        });
      }

      this.overlay = false;
    },
  },
};
</script>
