import Vue from 'vue';
import VueRouter from 'vue-router';

import Users from '@/views/Users.vue';
import Towns from '@/views/Towns.vue';
import Associations from '@/views/Associations.vue';
import Farmers from '@/views/Farmers.vue';
import Support from '@/views/Support.vue';
import Profile from '@/views/Profile.vue';
import Locations from '@/views/Locations.vue';
import Products from '@/views/Products.vue';
import Crops from '@/views/Crops.vue';
import Visits from '@/views/Visits.vue';
import School from '@/views/School.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Users,
    meta: { title: 'Usuarios' },
  },
  {
    path: '/municipios',
    name: 'municipios',
    component: Towns,
    meta: { title: 'Municipios' },
  },
  {
    path: '/veredas',
    name: 'veredas',
    component: Locations,
    meta: { title: 'Veredas' },
  },
  {
    path: '/productos',
    name: 'productos',
    component: Products,
    meta: { title: 'Productos' },
  },
  {
    path: '/cultivos',
    name: 'cultivos',
    component: Crops,
    meta: { title: 'Cultivos' },
  },
  {
    path: '/asociaciones',
    name: 'asociaciones',
    component: Associations,
    meta: { title: 'Asociaciones' },
  },
  {
    path: '/productores',
    name: 'productores',
    component: Farmers,
    meta: { title: 'Productores' },
  },
  {
    path: '/asistencia',
    name: 'asistencia',
    component: Support,
    meta: { title: 'Asistencia técnica' },
  },
  {
    path: '/visitas',
    name: 'visitas',
    component: Visits,
    meta: { title: 'Visitas' },
  },
  {
    path: '/escuela',
    name: 'escuela',
    component: School,
    meta: { title: 'Escuela de técnicos' },
  },
  {
    path: '/',
    name: 'inicio',
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: Profile,
    meta: { title: 'Perfil de usuario' },
  },
  {
    path: '*',
    redirect: {
      name: 'inicio',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/app',
  routes,
});

export default router;
