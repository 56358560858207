<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <v-form @submit.prevent="submitForm" ref="form">
      <v-card outlined max-width="600">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|min:6"
                  name="contraseña actual"
                >
                  <v-text-field
                    v-model="formData.oldPass"
                    :append-icon="showOldPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showOldPass ? 'text' : 'password'"
                    dense
                    clearable
                    label="Contraseña actual*"
                    :error-messages="errors"
                    required
                    @click:append="showOldPass = !showOldPass"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|min:6"
                  name="la contraseña"
                >
                  <v-text-field
                    v-model="formData.newPass"
                    :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showNewPass ? 'text' : 'password'"
                    dense
                    clearable
                    label="Contraseña nueva*"
                    :error-messages="errors"
                    required
                    @click:append="showNewPass = !showNewPass"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|min:6|confirmed:la contraseña"
                  name="nuevamente la contraseña"
                >
                  <v-text-field
                    v-model="formData.reNewPass"
                    :append-icon="showReNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showReNewPass ? 'text' : 'password'"
                    dense
                    clearable
                    label="Repetir contraseña nueva*"
                    :error-messages="errors"
                    required
                    @click:append="showReNewPass = !showReNewPass"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
          <small>*Indica campo requerido</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" color="green darken-2" :disabled="invalid" text>
            Cambiar contraseña
          </v-btn>
        </v-card-actions>

        <v-overlay :value="overlay" absolute>
          <v-progress-circular indeterminate size="48" />
        </v-overlay>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';

import { required, min, confirmed } from 'vee-validate/dist/rules';
import gql from 'graphql-tag';

import restrictedActionMixin from '@/mixins/restrictedActionMixin';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar la {_field_}',
});

extend('min', {
  ...min,
  message: 'Ingrese por lo menos {length} caracteres',
});

extend('confirmed', {
  ...confirmed,
  message: 'Las contraseñas deben coincidir',
});

export default {
  name: 'ChangePass',

  components: { ValidationProvider, ValidationObserver },

  mixins: [restrictedActionMixin],

  data: () => ({
    overlay: false,

    showOldPass: false,
    showNewPass: false,
    showReNewPass: false,

    formData: {
      oldPass: '',
      newPass: '',
      reNewPass: '',
    },
  }),

  methods: {
    async submitForm() {
      this.overlay = true;

      const mutation = gql`
        mutation changePass($input: ChangePassInput) {
          changePass(input: $input) {
            success
            message
          }
        }
      `;

      const {
        data: { changePass },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: {
            input: {
              oldPass: this.formData.oldPass,
              newPass: this.formData.newPass,
            },
          },
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      if (changePass.success) {
        this.$refs.form.reset();
        this.$refs.observer.reset();
        this.$store.commit('snackbar/setSnack', {
          message: changePass.message,
          color: 'success',
        });
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: changePass.message,
          color: 'error',
        });
      }

      this.overlay = false;
    },
  },
};
</script>
