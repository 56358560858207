<template>
  <div>
    <support-admin-view v-if="userRole && userRole == 'ADMIN'" />
    <support-agronomist-view v-if="userRole && userRole == 'AGRONOMIST'" />
    <support-technician-view v-else-if="userRole && userRole == 'TECHNICIAN'" />
    <support-farmer-view v-else-if="userRole && userRole == 'FARMER'" />
  </div>
</template>

<script>
import SupportAdminView from '@/components/support/SupportAdminView.vue';
import SupportAgronomistView from '@/components/support/SupportAgronomistView.vue';
import SupportFarmerView from '@/components/support/SupportFarmerView.vue';

import { mapGetters } from 'vuex';

export default {
  components: {
    SupportAdminView,
    SupportAgronomistView,
    SupportFarmerView,
  },

  data: () => ({}),

  computed: {
    ...mapGetters({ userRole: 'userData/userRole' }),
  },
};
</script>
