<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="tableSchoolArticles"
      :options.sync="options"
      :server-items-length="totalSchoolArticles"
      :items-per-page="12"
      :footer-props="{
        'disable-items-per-page': true,
        itemsPerPageOptions: [12],
      }"
      @page-count="pageCount = $event"
      no-data-text="No hay información publicada"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />

          <v-btn color="primary" fab x-small @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-dialog
              v-model="dialog"
              v-if="dialog"
              max-width="600px"
              persistent
              scrollable
            >
              <v-form @submit.prevent="submitForm">
                <v-card>
                  <v-card-title>
                    <span class="text-button grey--text text--darken-1">
                      {{ formTitle }}
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el título de la información"
                          >
                            <v-text-field
                              v-model="selectedItem.title"
                              dense
                              clearable
                              label="Título de la información*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                          <transition name="expand">
                            <v-img
                              v-show="infographyPhotoURL"
                              class="mb-4 rounded-sm grey lighten-3 overflow-hidden"
                              :src="infographyPhotoURL"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-2"
                                  />
                                </v-row>
                              </template>
                            </v-img>
                          </transition>
                          <ValidationProvider
                            ref="infographyProvider"
                            v-slot="{ validate, errors }"
                            rules="required|image"
                            name="la foto de la infografía"
                          >
                            <v-file-input
                              v-model="selectedItem.infography"
                              label="Imagen infografía*"
                              accept="image/*"
                              enctype="multipart/form-data"
                              dense
                              prepend-icon="mdi-file-image-outline"
                              :error-messages="errors"
                              :loading="infographyLoading"
                              @click:clear="infographyPhotoURL = null"
                              @change="handleInfographyChange"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model.trim="selectedItem.info"
                            dense
                            auto-grow
                            rows="3"
                            clearable
                            label="Descripción"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>*Indica campo requerido</small>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />

                    <v-btn color="grey darken-1" text @click="close">
                      Cancelar
                    </v-btn>

                    <v-btn
                      type="submit"
                      color="green darken-2"
                      :disabled="invalid"
                      text
                    >
                      {{ formActionBtn }}
                    </v-btn>
                  </v-card-actions>

                  <v-overlay :value="overlay" absolute>
                    <v-progress-circular indeterminate size="48" />
                  </v-overlay>
                </v-card>
              </v-form>
            </v-dialog>
          </ValidationObserver>

          <v-dialog
            v-model="dialogDelete"
            v-if="dialogDelete"
            max-width="600px"
            persistent
          >
            <v-card>
              <v-card-title
                class="grey--text text--darken-1 font-weight-regular text-subtitle-1"
              >
                ¿Está seguro que desea eliminar la información
                <b>&nbsp;{{ selectedItem.title }}</b
                >?
              </v-card-title>

              <v-card-text>
                La información no podrá ser recuperada.
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="grey darken-1" text @click="closeDelete">
                  Cancelar
                </v-btn>
                <v-btn color="red darken-1" text @click="deleteItemConfirm">
                  Eliminar la información
                </v-btn>
              </v-card-actions>

              <v-overlay :value="overlay" absolute>
                <v-progress-circular indeterminate size="48" />
              </v-overlay>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template #footer.prepend>
        <v-spacer class="py-6" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('es');
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.tz.setDefault('America/Bogota');

import axios from 'axios';
import Compressor from 'compressorjs';
import { nanoid } from 'nanoid';

import gql from 'graphql-tag';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

export default {
  name: 'SchoolAgronomistView',

  components: { ValidationProvider, ValidationObserver },

  mixins: [restrictedActionMixin],

  data: () => ({
    overlay: false,
    infographyLoading: false,
    infographyPhotoURL: null,

    dialog: false,
    dialogDelete: false,
    loading: false,
    options: {},
    totalSchoolArticles: 0,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Fecha',
        align: 'start',
        sortable: false,
        value: 'humanDate',
      },
      { text: 'Título', sortable: false, value: 'title' },
      { text: 'Acción', sortable: false, value: 'actions', align: 'end' },
    ],
    schoolArticles: [],
    tableSchoolArticles: [],
    selectedIndex: -1,
    selectedItem: {},
    defaultItem: {},
  }),

  computed: {
    formTitle() {
      return this.selectedIndex === -1
        ? 'Agregar información'
        : 'Editar información';
    },

    formActionBtn() {
      return this.selectedIndex === -1
        ? 'Agregar información'
        : 'Editar información';
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async handleInfographyChange(file) {
      const { valid } = await this.$refs.infographyProvider.validate(file);

      if (valid) {
        this.infographyLoading = true;

        this.selectedItem.infographyPhoto = await this.compressPhoto(file);

        this.infographyPhotoURL = this.generateFileURL(
          this.selectedItem.infographyPhoto
        );

        this.infographyLoading = false;
      }
    },

    async compressPhoto(photo) {
      const result = await new Promise((resolve, reject) => {
        new Compressor(photo, {
          quality: 0.8,
          maxWidth: 1200,
          maxHeight: 1200,

          success: resolve,
          error: reject,
        });
      });

      return result;
    },

    generateFileURL(file) {
      const fileURL = URL.createObjectURL(file);
      URL.revokeObjectURL(file); // Liberar memoria

      return fileURL;
    },

    generateFileName(file) {
      const name = nanoid();
      const extension = file.name.split('.').pop();

      return `${name}.${extension}`;
    },

    async initialize() {
      this.loading = true;
      const query = gql`
        query getSchoolArticles($input: GetSchoolArticlesInput!) {
          getSchoolArticles(input: $input) {
            schoolArticles {
              id
              date
              title
              image
              info
            }

            total
          }
        }
      `;

      const {
        data: { getSchoolArticles },
      } = await this.$apollo
        .query({
          query,
          variables: {
            input: {
              page: this.options.page,
              perPage: this.options.itemsPerPage,
            },
          },
          fetchPolicy: 'network-only',
        })
        .catch((error) => {
          this.loading = false;

          this.restrictedAction(error);
          return;
        });

      this.schoolArticles = getSchoolArticles.schoolArticles;
      this.totalSchoolArticles = getSchoolArticles.total;

      const tableSchoolArticles = [];
      getSchoolArticles.schoolArticles.map((schoolArticle) => {
        tableSchoolArticles.push({
          id: schoolArticle.id,
          humanDate: dayjs(schoolArticle.date).format('LL'),
          title: schoolArticle.title,
          image: schoolArticle.image,
        });
      });

      this.tableSchoolArticles = tableSchoolArticles;

      this.loading = false;
    },

    editItem({ id }) {
      this.selectedIndex = this.schoolArticles.findIndex(
        (item) => item.id == id
      );
      this.selectedItem = Object.assign(
        {},
        this.schoolArticles[this.selectedIndex]
      );

      this.infographyPhotoURL = this.selectedItem.image;
      this.selectedItem.infography = new File([], 'Infografía.jpg', {
        type: 'image/jpeg',
      });

      this.dialog = true;
    },

    deleteItem(item) {
      this.selectedIndex = this.schoolArticles.indexOf(item);
      this.selectedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.overlay = true;

      const mutation = gql`
        mutation deleteSchoolArticle($input: DeleteSchoolArticleInput) {
          deleteSchoolArticle(input: $input) {
            success
            message
          }
        }
      `;

      const { data } = await axios.post('/delete', {
        id: this.selectedItem.id,
        infographyPhoto: true,
      });

      if (!data.success) {
        this.$store.commit('snackbar/setSnack', {
          message: data.message,
          color: 'error',
        });

        this.overlay = false;

        return;
      }

      const {
        data: { deleteSchoolArticle },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: {
            input: {
              id: this.selectedItem.id,
            },
          },
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      this.overlay = false;

      if (deleteSchoolArticle.success) {
        this.initialize();
        this.closeDelete();
      }

      this.$store.commit('snackbar/setSnack', {
        message: deleteSchoolArticle.message,
        color: deleteSchoolArticle.success ? 'success' : 'error',
      });
    },

    close() {
      this.dialog = false;
      this.infographyPhotoURL = null;

      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    async submitForm() {
      this.overlay = true;

      let mutation;
      const variables = this.selectedItem;

      const infographyPhoto =
        this.selectedItem.infographyPhoto || this.selectedItem.image;

      delete variables.infography;
      delete variables.infographyPhoto;

      if (infographyPhoto) {
        const formData = new FormData();

        if (this.selectedIndex > -1) {
          // Editar artículo
          mutation = gql`
            mutation editSchoolArticle($input: DataSchoolArticleInput) {
              editSchoolArticle(input: $input) {
                success
                message
              }
            }
          `;

          const axiosVariables = {
            id: this.selectedItem.id,
            infographyPhoto: true,
          };

          if (typeof infographyPhoto == 'object') {
            const filename = this.generateFileName(infographyPhoto);
            formData.append('infography', infographyPhoto, filename);

            await axios.post('/delete', axiosVariables);
          }
        } else {
          mutation = gql`
            mutation addSchoolArticle($input: DataSchoolArticleInput) {
              addSchoolArticle(input: $input) {
                success
                message
              }
            }
          `;

          const filename = this.generateFileName(infographyPhoto);
          formData.append('infography', infographyPhoto, filename);
        }

        // Subir las nuevas fotos si las hay
        if (Array.from(formData.keys()).length > 0) {
          const { data } = await axios.post('/uploadInfography', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (data.success) {
            delete data.success;

            for (const image in data) {
              variables[image] = data[image];
            }
          } else {
            this.$store.commit('snackbar/setSnack', {
              message: data.message,
              color: 'error',
            });

            this.overlay = false;

            return;
          }
        }
      }

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      let isSuccess, message;
      if (this.selectedIndex > -1) {
        isSuccess = data.editSchoolArticle.success;
        message = data.editSchoolArticle.message;
      } else {
        isSuccess = data.addSchoolArticle.success;
        message = data.addSchoolArticle.message;
      }

      if (isSuccess) {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'success',
        });

        this.initialize(); // TODO: revisar si en lugar de esto uso el refetch query

        this.close();
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'error',
        });
      }

      this.overlay = false;
    },
  },
};
</script>
