<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="tableUsers"
      :options.sync="options"
      :server-items-length="totalUsers"
      :items-per-page="12"
      :footer-props="{
        'disable-items-per-page': true,
        itemsPerPageOptions: [12],
      }"
      @page-count="pageCount = $event"
      no-data-text="No hay usuarios registrados"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />

          <v-btn color="primary" fab x-small @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-dialog
              v-model="dialog"
              v-if="dialog"
              max-width="600px"
              persistent
              scrollable
            >
              <v-form @submit.prevent="submitForm">
                <v-card>
                  <v-card-title>
                    <span class="text-button grey--text text--darken-1">{{
                      formTitle
                    }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el nombre del usuario"
                          >
                            <v-text-field
                              v-model="selectedItem.name"
                              dense
                              clearable
                              label="Nombre/s usuario*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el apellido del usuario"
                          >
                            <v-text-field
                              v-model="selectedItem.lastname"
                              dense
                              clearable
                              label="Apellidos/s usuario*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el tipo de documento"
                          >
                            <v-select
                              v-model="selectedItem.documentType"
                              :items="[
                                { text: 'Cédula Ciudadanía', value: 'CC' },
                                { text: 'NIT', value: 'NIT' },
                                { text: 'Cédula Extranjería', value: 'CE' },
                              ]"
                              dense
                              clearable
                              label="Tipo de documento*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|numeric|min:7"
                            name="el número de documento"
                          >
                            <v-text-field
                              v-model="selectedItem.documentNumber"
                              dense
                              clearable
                              label="Número documento*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="la dirección"
                          >
                            <v-text-field
                              v-model="selectedItem.address"
                              dense
                              clearable
                              label="Dirección*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="email|required"
                            name="el correo"
                          >
                            <v-text-field
                              v-model="selectedItem.email"
                              dense
                              clearable
                              label="Correo electrónico*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="numeric|digits:10|required"
                            name="el celular"
                          >
                            <v-text-field
                              v-model="selectedItem.mobile"
                              dense
                              clearable
                              label="Celular*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el tipo de usuario"
                          >
                            <v-select
                              v-model="selectedItem.role"
                              :items="[
                                { text: 'Técnico', value: 'TECHNICIAN' },
                                { text: 'Agrónomo', value: 'AGRONOMIST' },
                              ]"
                              dense
                              clearable
                              label="Tipo de usuario*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el estado"
                          >
                            <v-select
                              v-model="selectedItem.status"
                              :items="[
                                { text: 'Activo', value: 'ACTIVE' },
                                { text: 'Inactivo', value: 'INACTIVE' },
                              ]"
                              dense
                              clearable
                              label="Estado*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>*Indica campo requerido</small>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />

                    <v-btn color="grey darken-1" text @click="close">
                      Cancelar
                    </v-btn>

                    <v-btn
                      type="submit"
                      color="green darken-2"
                      :disabled="invalid"
                      text
                    >
                      {{ formActionBtn }}
                    </v-btn>
                  </v-card-actions>

                  <v-overlay :value="overlay" absolute>
                    <v-progress-circular indeterminate size="48" />
                  </v-overlay>
                </v-card>
              </v-form>
            </v-dialog>
          </ValidationObserver>

          <v-dialog
            v-model="dialogDelete"
            v-if="dialogDelete"
            max-width="600px"
            persistent
          >
            <v-card>
              <v-card-title
                class="grey--text text--darken-1 font-weight-regular text-subtitle-1"
              >
                ¿Está seguro que desea eliminar el usuario
                <b>&nbsp;{{ selectedItem.name }} {{ selectedItem.lastname }}</b
                >?
              </v-card-title>
              <v-card-text>
                La información no podrá ser recuperada.
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="grey darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="red darken-1" text @click="deleteItemConfirm"
                  >Eliminar usuario</v-btn
                >
              </v-card-actions>

              <v-overlay :value="overlay" absolute>
                <v-progress-circular indeterminate size="48" />
              </v-overlay>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template #footer.prepend>
        <v-spacer class="py-6" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('es');
dayjs.extend(relativeTime);

import gql from 'graphql-tag';

import capitalizeTextMixin from '@/mixins/capitalizeTextMixin';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required, email, numeric, min, digits } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

extend('email', {
  ...email,
  message: 'Debe ingresar un correo válido',
});

extend('numeric', {
  ...numeric,
  message: 'Ingrese sólo números',
});

extend('min', {
  ...min,
  message: 'Ingrese por lo menos {length} números',
});

extend('digits', {
  ...digits,
  message: 'Ingrese 10 dígitos',
});

export default {
  components: { ValidationProvider, ValidationObserver },

  mixins: [capitalizeTextMixin, restrictedActionMixin],

  data: () => ({
    overlay: false,

    dialog: false,
    dialogDelete: false,
    loading: false,
    options: {},
    totalUsers: 0,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Nombre usuario',
        align: 'start',
        sortable: false,
        value: 'fullname',
      },
      { text: 'Perfil', sortable: false, value: 'role' },
      { text: 'Fecha registro', sortable: false, value: 'lastAccess' },
      { text: 'Estado', sortable: false, value: 'status' },
      { text: 'Acciones', sortable: false, value: 'actions', align: 'end' },
    ],
    users: [],
    tableUsers: [],
    selectedIndex: -1,
    selectedItem: {},
    defaultItem: {
      documentType: 'CC',
      role: 'TECHNICIAN',
      status: 'ACTIVE',
    },
  }),

  computed: {
    formTitle() {
      return this.selectedIndex === -1 ? 'Crear usuario' : 'Editar usuario';
    },

    formActionBtn() {
      return this.selectedIndex === -1 ? 'Crear usuario' : 'Editar usuario';
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.selectedItem = Object.assign({}, this.defaultItem);
  },

  methods: {
    async initialize() {
      this.loading = true;
      const query = gql`
        query getUsers($input: GetUsersInput!) {
          getUsers(input: $input) {
            users {
              name
              lastname
              documentNumber
              documentType
              address
              email
              mobile
              lastAccess
              role
              status
              id
            }
            total
          }
        }
      `;

      const {
        data: { getUsers },
      } = await this.$apollo
        .query({
          query,
          variables: {
            input: {
              page: this.options.page,
              perPage: this.options.itemsPerPage,
            },
          },
          fetchPolicy: 'network-only',
        })
        .catch((error) => {
          this.loading = false;

          this.restrictedAction(error);
          return;
        });

      this.users = getUsers.users;
      this.totalUsers = getUsers.total;

      const tableUsers = [];
      getUsers.users.map((user) => {
        tableUsers.push({
          id: user.id,
          fullname: `${user.name} ${user.lastname}`,
          role: user.role == 'TECHNICIAN' ? 'Técnico' : 'Agrónomo',
          // lastAccess: this.capitalizeText(dayjs(user.lastAccess).fromNow()),
          lastAccess: dayjs(user.lastAccess).format('LL'),
          status: user.status == 'ACTIVE' ? 'Activo' : 'Inactivo',
        });

        return user;
      });

      this.tableUsers = tableUsers;

      this.loading = false;
    },

    editItem({ id }) {
      this.selectedIndex = this.users.findIndex((item) => item.id == id);
      this.selectedItem = Object.assign({}, this.users[this.selectedIndex]);
      this.dialog = true;
    },

    deleteItem({ id }) {
      this.selectedIndex = this.users.findIndex((item) => item.id == id);
      this.selectedItem = Object.assign({}, this.users[this.selectedIndex]);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.overlay = true;

      const mutation = gql`
        mutation deleteUser($input: DeleteUserInput) {
          deleteUser(input: $input) {
            success
            message
          }
        }
      `;

      const {
        data: { deleteUser },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: {
            input: {
              id: this.selectedItem.id,
            },
          },
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      this.overlay = false;

      if (deleteUser.success) {
        this.initialize();
        this.closeDelete();
      }

      this.$store.commit('snackbar/setSnack', {
        message: deleteUser.message,
        color: deleteUser.success ? 'success' : 'error',
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    async submitForm() {
      this.overlay = true;

      let mutation;
      const variables = this.selectedItem;
      delete variables.lastAccess;

      if (this.selectedIndex > -1) {
        mutation = gql`
          mutation editUser($input: DataUserInput) {
            editUser(input: $input) {
              success
              message
            }
          }
        `;
      } else {
        mutation = gql`
          mutation addUser($input: DataUserInput) {
            addUser(input: $input) {
              success
              message
            }
          }
        `;
      }

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      let isSuccess, message;
      if (this.selectedIndex > -1) {
        isSuccess = data.editUser.success;
        message = data.editUser.message;
      } else {
        isSuccess = data.addUser.success;
        message = data.addUser.message;
      }

      if (isSuccess) {
        this.$store.commit('snackbar/setSnack', {
          message,
          color: 'success',
        });

        this.initialize(); // TODO: revisar si en lugar de esto uso el refetch query

        this.close();
      } else {
        this.$store.commit('snackbar/setSnack', {
          message,
          color: 'error',
        });
      }

      this.overlay = false;
    },
  },
};
</script>
