<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="tableSupports"
      :options.sync="options"
      :server-items-length="totalSupports"
      :items-per-page="12"
      :footer-props="{
        'disable-items-per-page': true,
        itemsPerPageOptions: [12],
      }"
      @page-count="pageCount = $event"
      no-data-text="No tiene asistencias registradas"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />

          <v-btn color="primary" fab x-small @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-dialog
            v-model="dialogDatasheet"
            v-if="dialogDatasheet"
            max-width="600px"
            persistent
            scrollable
          >
            <v-form @submit.prevent="submitForm">
              <v-card>
                <v-card-title>
                  <v-tabs
                    v-model="activeTab"
                    background-color="transparent"
                    color="primary"
                    show-arrows
                    grow
                  >
                    <v-tab>Asistencia</v-tab>
                    <v-tab>Visitas</v-tab>
                    <v-tab>Mensajes</v-tab>
                  </v-tabs>
                </v-card-title>

                <v-card-text id="divCardText">
                  <v-tabs-items v-model="activeTab">
                    <v-tab-item
                      v-for="(tabComponent, index) in tabsComponents"
                      :key="index"
                    >
                      <component
                        :is="tabComponent.component"
                        :supportId="selectedItem.id"
                        :farmerId="selectedItem.farmerId"
                        :status="selectedItem.status"
                        @loading="overlay = $event"
                        @closeDialog="closeDatasheet"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />

                  <v-btn color="grey darken-1" text @click="closeDatasheet">
                    Cerrar
                  </v-btn>
                </v-card-actions>

                <v-overlay :value="overlay" absolute>
                  <v-progress-circular indeterminate size="48" />
                </v-overlay>
              </v-card>
            </v-form>
          </v-dialog>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-dialog
              v-model="dialog"
              v-if="dialog"
              max-width="600px"
              persistent
              scrollable
            >
              <v-form @submit.prevent="submitForm">
                <v-card>
                  <v-card-title>
                    <span class="text-button grey--text text--darken-1">
                      {{ formTitle }}
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el cultivo"
                          >
                            <v-autocomplete
                              v-model="selectedItem.crop.id"
                              dense
                              eager
                              clearable
                              small-chips
                              hide-selected
                              label="Cultivo*"
                              :error-messages="errors"
                              :items="cropsList"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <transition name="expand">
                            <v-img
                              v-show="cropGeneralURL"
                              height="140"
                              class="mb-4 rounded-sm grey lighten-3 overflow-hidden"
                              :src="cropGeneralURL"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-2"
                                  />
                                </v-row>
                              </template>
                            </v-img>
                          </transition>
                          <!-- @click="$refs.cropGeneral.$refs.input.click()" -->
                          <!-- Esto funciona en la etiqueta v-img -->
                          <ValidationProvider
                            ref="cropGeneralProvider"
                            v-slot="{ validate, errors }"
                            rules="required|image"
                            name="la foto del cultivo"
                          >
                            <v-file-input
                              v-model="selectedItem.cropGeneral"
                              label="Foto cultivo*"
                              accept="image/*"
                              enctype="multipart/form-data"
                              dense
                              prepend-icon="mdi-file-image-outline"
                              :error-messages="errors"
                              :loading="cropGeneralLoading"
                              @click:clear="cropGeneralURL = null"
                              @change="handleCropGeneralChange"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <transition name="expand">
                            <v-img
                              v-show="selectedItem.cropCloseUp"
                              height="140"
                              class="mb-4 rounded-sm grey lighten-3 overflow-hidden"
                              :src="cropCloseUpURL"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-2"
                                  />
                                </v-row>
                              </template>
                            </v-img>
                          </transition>
                          <ValidationProvider
                            ref="cropCloseUpProvider"
                            v-slot="{ validate, errors }"
                            rules="required|image"
                            name="la foto del daño"
                          >
                            <v-file-input
                              v-model="selectedItem.cropCloseUp"
                              label="Foto daño*"
                              accept="image/*"
                              enctype="multipart/form-data"
                              dense
                              prepend-icon="mdi-file-image-outline"
                              :error-messages="errors"
                              :loading="false"
                              @click:clear="cropCloseUpURL = null"
                              @change="handleCropCloseUpChange"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                          <v-row
                            justify="space-between"
                            align="center"
                            no-gutters
                          >
                            <v-switch
                              dense
                              inset
                              label="¿Ha aplicado productos?"
                              v-model="selectedItem.wereProductsApplied"
                            />
                            <v-btn
                              v-if="selectedItem.wereProductsApplied"
                              color="primary"
                              fab
                              x-small
                              @click="appliedProducts.push({})"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-row>
                        </v-col>
                        <!-- <v-container> -->
                        <!-- <transition name="expand"> -->
                        <div v-if="selectedItem.wereProductsApplied">
                          <v-container
                            fluid
                            v-for="(row, index) in appliedProducts"
                            :key="index"
                          >
                            <v-row align="center">
                              <v-col cols="12" sm="4">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="el producto"
                                >
                                  <v-text-field
                                    v-model="appliedProducts[index]['name']"
                                    dense
                                    clearable
                                    label="Producto usado*"
                                    :error-messages="errors"
                                  />
                                </ValidationProvider>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="la dosis"
                                >
                                  <v-text-field
                                    v-model="appliedProducts[index]['dosage']"
                                    dense
                                    clearable
                                    label="Dosis*"
                                    :error-messages="errors"
                                  />
                                </ValidationProvider>
                              </v-col>
                              <v-col cols="10" sm="4">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="el volumen"
                                >
                                  <v-text-field
                                    v-model="appliedProducts[index]['volume']"
                                    dense
                                    clearable
                                    label="Volumen*"
                                    :error-messages="errors"
                                  />
                                </ValidationProvider>
                              </v-col>
                              <v-col cols="2" sm="1" v-if="index > 0">
                                <v-btn
                                  color="error"
                                  icon
                                  @click="removeProduct(index)"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                        <!-- </transition> -->
                        <!-- </v-container> -->
                        <v-col cols="12">
                          <v-textarea
                            v-model.trim="selectedItem.situation"
                            dense
                            auto-grow
                            rows="3"
                            clearable
                            label="Descripción del problema"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>*Indica campo requerido</small>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />

                    <v-btn color="grey darken-1" text @click="close">
                      Cancelar
                    </v-btn>

                    <v-btn
                      type="submit"
                      color="green darken-2"
                      :disabled="invalid"
                      text
                    >
                      {{ formActionBtn }}
                    </v-btn>
                  </v-card-actions>

                  <v-overlay :value="overlay" absolute>
                    <v-progress-circular indeterminate size="48" />
                  </v-overlay>
                </v-card>
              </v-form>
            </v-dialog>
          </ValidationObserver>

          <v-dialog
            v-model="dialogDelete"
            v-if="dialogDelete"
            max-width="600px"
            persistent
          >
            <v-card>
              <v-card-title
                class="grey--text text--darken-1 font-weight-regular text-subtitle-1"
              >
                ¿Está seguro que desea eliminar la asistencia para el cultivo de
                <b>&nbsp;{{ selectedItem.cropName }}</b
                >?
              </v-card-title>

              <v-card-text>
                La información no podrá ser recuperada.
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="grey darken-1" text @click="closeDelete">
                  Cancelar
                </v-btn>
                <v-btn color="red darken-1" text @click="deleteItemConfirm">
                  Eliminar asistencia
                </v-btn>
              </v-card-actions>

              <v-overlay :value="overlay" absolute>
                <v-progress-circular indeterminate size="48" />
              </v-overlay>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="openDatasheet(item)">
          mdi-eye
        </v-icon>

        <v-icon
          class="mx-2"
          small
          @click="editItem(item)"
          :disabled="item.status == 'INACTIVE'"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          @click="deleteItem(item)"
          :disabled="item.status == 'INACTIVE'"
        >
          mdi-delete
        </v-icon>
      </template>

      <template #footer.prepend>
        <v-spacer class="py-6" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from '@/localdb/db.js';

import { mapGetters } from 'vuex';

import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import gql from 'graphql-tag';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('es');
dayjs.extend(localizedFormat);

import axios from 'axios';
import Compressor from 'compressorjs';
import { nanoid } from 'nanoid';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required, image } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

extend('image', {
  ...image,
  message: 'Solo se aceptan fotos',
});

import SupportSheetTab from '@/components/support/supportTabs/SupportSheetTab.vue';
import SupportVisitsTab from '@/components/support/supportTabs/SupportVisitsTab.vue';
import SupportMessageTab from '@/components/support/supportTabs/SupportMessageTab.vue';

export default {
  name: 'SupportFarmerView',

  components: {
    ValidationProvider,
    ValidationObserver,
    SupportSheetTab,
    SupportVisitsTab,
    SupportMessageTab,
  },

  mixins: [restrictedActionMixin],

  async mounted() {
    // Escuchar evento cuando se actualiza en background las asistencias
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data.action == 'update_supports') {
        this.initialize();
      }
    });

    const query = gql`
      query GetCropsByFarmer {
        getCropsByFarmer {
          crops {
            id
            product {
              name
            }
          }
        }
      }
    `;

    const {
      data: { getCropsByFarmer },
    } = await this.$apollo.query({ query, fetchPolicy: 'network-only' });

    const crops = [];
    await getCropsByFarmer.crops.map((crop) => {
      crops.push({ value: crop.id, text: crop.product.name });
    });

    this.cropsList = crops;

    await this.initialize();

    // Consulto los datos cacheados y actualizo la info en memoria
    if (!this.isConnected) {
      const toSupports = [];
      const toTableSupports = [];
      const localSupports = await db.support.toArray();
      localSupports.forEach((localSupport) => {
        toTableSupports.push({
          id: localSupport.id,
          offline: true,
          cropName: localSupport.cropName + ' (Local)',
          humanDate: dayjs().format('LL'),
          statusText: 'Activa',
          status: 'ACTIVE',
        });

        toSupports.push({
          id: localSupport.id,
          crop: {
            id: localSupport.variables.cropId,
            product: { text: localSupport.cropName },
          },
          appliedProducts: localSupport.variables.appliedProducts,
          cropGeneralPhoto: this.generateFileURL(
            localSupport.cropGeneralPhoto.image
          ),
          cropCloseUpPhoto: this.generateFileURL(
            localSupport.cropCloseUpPhoto.image
          ),
          situation: localSupport.variables.situation,
        });
      });

      this.tableSupports.unshift(...toTableSupports);
      this.cachedSupports.unshift(...toSupports);
    }
  },

  data: () => ({
    overlay: false,

    dialog: false,
    dialogDelete: false,
    dialogDatasheet: false,
    search: '',
    loading: false,
    options: {},
    totalSupports: 0,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Cultivo',
        align: 'start',
        sortable: false,
        value: 'cropName',
      },
      {
        text: 'Fecha',
        sortable: false,
        value: 'humanDate',
      },
      // { text: 'Situación', sortable: false, value: 'recommendation' },
      {
        text: 'Estado',
        sortable: false,
        value: 'statusText',
      },
      { text: 'Acciones', sortable: false, value: 'actions', align: 'end' },
    ],
    supports: [],
    tableSupports: [],
    cachedSupports: [],
    cropsList: [],
    selectedIndex: -1,
    selectedItem: {},
    defaultItem: { crop: {} },

    tabsComponents: [
      { component: SupportSheetTab },
      { component: SupportVisitsTab },
      { component: SupportMessageTab },
    ],
    activeTab: 0,

    cropGeneralURL: null,
    cropCloseUpURL: null,
    cropGeneralLoading: false,
    cropCloseUpLoading: false,

    appliedProducts: [{}],
  }),

  computed: {
    formTitle() {
      return this.selectedIndex === -1
        ? 'Solicitar asistencia'
        : 'Editar asistencia';
    },

    formActionBtn() {
      return this.selectedIndex === -1
        ? 'Solicitar asistencia'
        : 'Editar asistencia';
    },

    ...mapGetters({
      isConnected: 'network/isConnected',
    }),
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.selectedItem = Object.assign({}, this.defaultItem);
  },

  methods: {
    /*filterSearch(val) {
      console.log(val);
    },*/

    async handleCropGeneralChange(file) {
      const { valid } = await this.$refs.cropGeneralProvider.validate(file);

      //console.log(this.$refs.generalPhoto.$refs.input.value);

      if (valid) {
        this.cropGeneralLoading = true;

        this.selectedItem.cropGeneralPhoto = await this.compressPhoto(file);

        this.cropGeneralURL = this.generateFileURL(
          this.selectedItem.cropGeneralPhoto
        );

        this.cropGeneralLoading = false;
      }
    },

    async handleCropCloseUpChange(file) {
      const { valid } = await this.$refs.cropCloseUpProvider.validate(file);

      //console.log(this.$refs.generalPhoto.$refs.input.value);

      if (valid) {
        this.cropCloseUpLoading = true;

        this.selectedItem.cropCloseUpPhoto = await this.compressPhoto(file);

        this.cropCloseUpURL = this.generateFileURL(
          this.selectedItem.cropCloseUpPhoto
        );

        this.cropCloseUpLoading = false;
      }
    },

    async initialize() {
      this.loading = true;
      const query = gql`
        query getSupportsPaginated($input: GetSupportsPaginatedInput!) {
          getSupportsPaginated(input: $input) {
            supports {
              id
              date
              cropGeneralPhoto
              cropCloseUpPhoto
              status
              situation

              crop {
                id
                date

                product {
                  value: id
                  text: name
                }
              }

              appliedProducts {
                name
                volume
                dosage
              }
            }

            total
          }
        }
      `;

      const {
        data: { getSupportsPaginated },
      } = await this.$apollo.query({
        query,
        variables: {
          input: {
            page: this.options.page,
            perPage: this.options.itemsPerPage,
          },
        },
        fetchPolicy: 'network-only',
      });

      this.supports = getSupportsPaginated.supports;
      this.totalSupports = getSupportsPaginated.total;

      const tableSupports = [];
      getSupportsPaginated.supports.forEach((support) => {
        tableSupports.push({
          id: support.id,
          cropName: support.crop.product.text,
          humanDate: dayjs(support.date).format('LL'),
          statusText: `${support.status == 'ACTIVE' ? 'Activa' : 'Inactiva'}`,
          status: support.status,
        });
      });

      this.tableSupports = tableSupports;

      this.loading = false;
    },

    async editItem(item) {
      // this.selectedIndex = this.supports.indexOf(item);
      // this.selectedItem = Object.assign({}, item);
      if (item.offline) {
        this.selectedIndex = this.cachedSupports.findIndex(
          (sup) => sup.id == item.id
        );
        this.selectedItem = Object.assign(
          {},
          this.cachedSupports[this.selectedIndex]
        );
      } else {
        if (!this.isConnected) {
          this.$store.commit('snackbar/setSnack', {
            message: 'Requiere conexión a internet',
            color: 'warning',
          });

          return;
        }

        this.selectedIndex = this.supports.findIndex(
          (sup) => sup.id == item.id
        );
        this.selectedItem = Object.assign(
          {},
          this.supports[this.selectedIndex]
        );
      }

      this.cropGeneralURL = this.selectedItem.cropGeneralPhoto;
      this.cropCloseUpURL = this.selectedItem.cropCloseUpPhoto;

      this.selectedItem.cropGeneral = new File([], 'Foto-cultivo.jpg', {
        type: 'image/jpeg',
      });

      this.selectedItem.cropCloseUp = new File([], 'Foto-daño.jpg', {
        type: 'image/jpeg',
      });

      if (this.selectedItem.appliedProducts.length > 0) {
        const appliedProducts = [];
        this.selectedItem.appliedProducts.map((product, index) => {
          appliedProducts[index] = {
            name: product.name,
            dosage: product.dosage,
            volume: product.volume,
          };
        });

        this.appliedProducts = appliedProducts;

        // Se asigna con $set para que el valor sea reactivo y tanto el botón como la lista se comporten como debe
        this.$set(this.selectedItem, 'wereProductsApplied', true);
      }

      this.dialog = true;
    },

    deleteItem(item) {
      this.selectedIndex = this.supports.indexOf(item);
      this.selectedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      if (!this.isConnected) {
        if (this.selectedItem.offline) {
          await db.support.delete(this.selectedItem.id);

          // Borrar de memoria
          this.supports.forEach((support, index) => {
            if (support.id == this.selectedItem.id) {
              this.tableSupports.splice(index, 1);
              this.supports.splice(index, 1);
            }
          });

          this.$store.commit('snackbar/setSnack', {
            message: 'Asistencia eliminada correctamente',
            color: 'success',
          });

          this.closeDelete();

          return;
        }

        this.$store.commit('snackbar/setSnack', {
          message: 'Requiere conexión a internet',
          color: 'warning',
        });

        this.closeDelete();

        return;
      }

      this.overlay = true;

      const mutation = gql`
        mutation deleteSupport($input: DeleteSupportInput) {
          deleteSupport(input: $input) {
            success
            message
          }
        }
      `;

      const { data } = await axios.post('/delete', {
        id: this.selectedItem.id,
        cropGeneralPhoto: true,
        cropCloseUpPhoto: true,
      });

      if (!data.success) {
        this.$store.commit('snackbar/setSnack', {
          message: data.message,
          color: 'error',
        });

        this.overlay = false;

        return;
      }

      const {
        data: { deleteSupport },
      } = await this.$apollo.mutate({
        mutation,
        variables: {
          input: {
            id: this.selectedItem.id,
          },
        },
      });

      this.overlay = false;

      if (deleteSupport.success) {
        this.initialize();
        this.closeDelete();
      }

      this.$store.commit('snackbar/setSnack', {
        message: deleteSupport.message,
        color: deleteSupport.success ? 'success' : 'error',
      });
    },

    openDatasheet(item) {
      this.selectedIndex = this.supports.indexOf(item);
      this.selectedItem = Object.assign({}, item);

      this.activeTab = 0;

      this.dialogDatasheet = true;
    },

    close() {
      this.dialog = false;
      this.cropGeneralURL = null;
      this.cropCloseUpURL = null;
      this.appliedProducts = [{}];

      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    closeDatasheet() {
      this.dialogDatasheet = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    async compressPhoto(photo) {
      const result = await new Promise((resolve, reject) => {
        new Compressor(photo, {
          quality: 0.8,
          maxWidth: 1200,
          maxHeight: 1200,

          success: resolve,
          error: reject,
        });
      });

      return result;
    },

    generateFileURL(file) {
      const fileURL = URL.createObjectURL(file);
      URL.revokeObjectURL(file); // Liberar memoria

      return fileURL;
    },

    generateFileName(file) {
      const name = nanoid();
      const extension = file.name.split('.').pop();

      return `${name}.${extension}`;
    },

    removeProduct(index) {
      this.appliedProducts.splice(index, 1);
    },

    async submitForm() {
      this.overlay = true;
      let dataToSave = {};

      let mutation;
      const variables = this.selectedItem;

      const cropGeneralPhoto = this.selectedItem.cropGeneralPhoto;
      const cropCloseUpPhoto = this.selectedItem.cropCloseUpPhoto;

      // Se hace limpieza de las variables a enviar
      if (this.selectedItem.wereProductsApplied) {
        variables.appliedProducts = JSON.parse(
          JSON.stringify(this.appliedProducts)
        );
      } else {
        variables.appliedProducts = [];
      }

      variables.cropId = variables.crop.id;

      delete variables.cropGeneral;
      delete variables.cropCloseUp;
      delete variables.wereProductsApplied;
      delete variables.crop;

      if (cropGeneralPhoto && cropCloseUpPhoto) {
        const formData = new FormData();

        if (this.selectedIndex > -1) {
          // Editar asistencia
          mutation = gql`
            mutation editSupport($input: DataSupportInput) {
              editSupport(input: $input) {
                success
                message
              }
            }
          `;

          const axiosVariables = {
            id: this.selectedItem.id,
            cropGeneralPhoto: false,
            cropCloseUpPhoto: false,
          };

          if (typeof cropGeneralPhoto == 'object') {
            const filename1 = this.generateFileName(cropGeneralPhoto);
            formData.append('cropGeneralPhoto', cropGeneralPhoto, filename1);
            axiosVariables.cropGeneralPhoto = true;
          }

          if (typeof cropCloseUpPhoto == 'object') {
            const filename2 = this.generateFileName(cropCloseUpPhoto);
            formData.append('cropCloseUpPhoto', cropCloseUpPhoto, filename2);
            axiosVariables.cropCloseUpPhoto = true;
          }

          delete variables.date;
          delete variables.status;

          // Eliminar la(s) foto(s) si es necesario
          if (
            (axiosVariables.cropGeneralPhoto == true ||
              axiosVariables.cropCloseUpPhoto == true) &&
            this.isConnected
          ) {
            await axios.post('/delete', axiosVariables);
          }
        } else {
          // Añadir nueva asistencia
          mutation = gql`
            mutation addSupport($input: DataSupportInput) {
              addSupport(input: $input) {
                success
                message
              }
            }
          `;

          const filename1 = this.generateFileName(cropGeneralPhoto);
          const filename2 = this.generateFileName(cropCloseUpPhoto);

          formData.append('cropGeneralPhoto', cropGeneralPhoto, filename1);
          formData.append('cropCloseUpPhoto', cropCloseUpPhoto, filename2);
        }

        // Subir las nuevas fotos si las hay
        if (Array.from(formData.keys()).length > 0) {
          if (this.isConnected) {
            const { data } = await axios.post('/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data', //TODO: cambiar esto para enviar el token, en el back añadir el middleware
              },
            });

            if (data.success) {
              delete data.success;

              for (const image in data) {
                variables[image] = data[image];
              }

              // variables.cropGeneralPhoto = data.cropGeneralPhoto;
              // variables.cropCloseUpPhoto = data.cropCloseUpPhoto;
            } else {
              this.$store.commit('snackbar/setSnack', {
                message: data.message,
                color: 'error',
              });

              this.overlay = false;

              return;
            }
          } else {
            for (const entry of formData.entries()) {
              dataToSave[entry[0]] = {
                image: entry[1],
                imageName: entry[1].name,
              };
            }
          }
        }
      }

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
          fetchPolicy: 'no-cache',
        })
        .catch(async () => {
          delete variables.cropGeneralPhoto;
          delete variables.cropCloseUpPhoto;

          dataToSave.token = localStorage.getItem('token') || null;
          dataToSave.refresh = localStorage.getItem('refresh') || null;
          dataToSave.variables = variables;

          const supportId =
            this.selectedIndex > -1 ? this.selectedItem.id : nanoid();

          const cropName = this.cropsList.find(
            (crop) => crop.value == variables.cropId
          );

          if (this.selectedIndex > -1) {
            await db.support.update(supportId, {
              cropName: cropName.text,
              ...dataToSave,
            });

            // Actualizo en memoria la nueva info
            const objIndex = this.tableSupports.findIndex(
              (support) => support.id == supportId
            );
            this.tableSupports[objIndex].cropName = cropName.text + ' (local)';

            this.supports[objIndex].crop = { id: variables.cropId };
            this.supports[objIndex].appliedProducts = variables.appliedProducts;
            this.supports[objIndex].situation = variables.situation;

            if (dataToSave.cropGeneralPhoto) {
              this.supports[objIndex].cropGeneralPhoto = this.generateFileURL(
                dataToSave.cropGeneralPhoto.image
              );
            }
            if (dataToSave.cropCloseUpPhoto) {
              this.supports[objIndex].cropCloseUpPhoto = this.generateFileURL(
                dataToSave.cropCloseUpPhoto.image
              );
            }

            this.$store.commit('snackbar/setSnack', {
              message: 'Asistencia editada correctamente',
              color: 'success',
            });
            this.overlay = false;
            this.close();
          } else {
            await db.support.put({
              id: supportId,
              cropName: cropName.text,
              ...dataToSave,
            });

            // Añado el nuevo soporte offline a la tabla
            this.tableSupports.unshift({
              id: supportId,
              offline: true,
              cropName: cropName.text + ' (local)',
              humanDate: dayjs().format('LL'),
              statusText: 'Activa',
              status: 'ACTIVE',
            });

            // Actualizo la info en memoria
            this.supports.unshift({
              id: supportId,
              crop: { id: variables.cropId },
              appliedProducts: variables.appliedProducts,
              cropGeneralPhoto: this.generateFileURL(
                dataToSave.cropGeneralPhoto.image
              ),
              cropCloseUpPhoto: this.generateFileURL(
                dataToSave.cropCloseUpPhoto.image
              ),
              situation: variables.situation,
            });

            // Registro el evento sync para que se ejecute cuando tenga conexión
            navigator.serviceWorker.getRegistration().then((reg) => {
              reg.sync.register('new-support');
            });

            this.$store.commit('snackbar/setSnack', {
              message: 'Asistencia solicitada correctamente',
              color: 'success',
            });
            this.overlay = false;
            this.close();
          }

          return;
        });

      let isSuccess, message;
      if (this.selectedIndex > -1) {
        isSuccess = data.editSupport.success;
        message = data.editSupport.message;
      } else {
        isSuccess = data.addSupport.success;
        message = data.addSupport.message;
      }

      if (isSuccess) {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'success',
        });

        this.initialize();

        this.close(); //FIXME: por alguna estúpida razón el autocomplete con v-model selectedItem.crop.id genera error porque crop es indefinido
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'error',
        });
      }

      this.overlay = false;
    },
  },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease;
  max-height: 140px;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
