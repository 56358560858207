<template>
  <v-dialog v-model="showPopupCloseSesion" persistent max-width="600">
    <v-card>
      <v-card-title class="text-h6 grey--text text--darken-1">
        Cerrar sesión
      </v-card-title>

      <v-card-text> ¿Está seguro que de desea cerrar la sesión? </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="grey darken-1" text @click="cancelAction">
          Cancelar
        </v-btn>

        <v-btn color="red lighten-1" text @click="closeSesion">
          Cerrar sesión
        </v-btn>
      </v-card-actions>

      <v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="48" />
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CloseSesionForm',
  props: {
    showPopupCloseSesion: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data: () => ({
    overlay: false,
  }),

  methods: {
    closeSesion() {
      this.overlay = true;

      //TODO: Acá llamado al server
      /*setTimeout(() => {
        this.overlay = false;
      }, 2000);*/

      this.$emit('closeCloseSesionPopup', 'reload');
    },

    cancelAction() {
      this.$emit('closeCloseSesionPopup');
    },
  },
};
</script>

<style></style>
