<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app floating color="white">
      <v-img
        src="@/assets/img/logoFedeprocap.svg"
        height="50"
        contain
        position="left center"
        class="mx-4 mt-1 mb-2"
      />

      <!-- <v-divider class="mb-2 grey lighten-2" /> -->

      <v-list nav dense>
        <v-list-item-group v-model="selectedItem" mandatory color="green">
          <v-list-item
            v-for="(item, i) in leftMenu"
            :key="i"
            :to="item.to"
            active-class="bg-active"
          >
            <v-list-item-icon class="mr-3">
              <v-icon v-text="item.icon" />
              <!-- <v-badge :content="10" color="error" :value="10" /> -->
              <!-- //TODO: habilitar para asistencias y mensajes a agrónomos, visitas a técnicos, mensajes y recomendaciones a productores -->
            </v-list-item-icon>
            <v-list-item-content class="mr-3">
              <v-list-item-title v-text="item.name" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <snackbar />

    <v-app-bar app elevation="0" color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer" />

      <v-toolbar-title class="grey--text text--darken-1">
        {{ sectionTitle }}
      </v-toolbar-title>
      <v-btn icon color="orange darken-3" v-if="installBtn" @click="installer">
        <v-icon>mdi-cog-play-outline</v-icon>
      </v-btn>
      <v-spacer />
      <v-menu transition="slide-y-transition" :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="grey darken-1" v-bind="attrs" v-on="on">
            {{ appUser.name }}
            <v-icon class="ml-2" color="green"> mdi-tooltip-account </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, i) in profileMenu"
            :key="i"
            :to="item.to"
            @click="
              item.click === 'openCloseSesionPopup'
                ? openCloseSesionPopup()
                : null
            "
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="bg-gradient">
      <v-container fluid>
        <router-view />
      </v-container>

      <close-sesion-dialog
        v-if="showPopupCloseSesion"
        :show-popup-close-sesion="showPopupCloseSesion"
        @closeCloseSesionPopup="closeCloseSesionPopup"
      />

      <v-snackbar
        bottom
        right
        :value="updateExists"
        :timeout="-1"
        color="primary"
      >
        Hay una actualización disponible.
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="refreshApp"> Actualizar </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        bottom
        left
        width="232"
        min-width="0"
        :value="!isOnline"
        :timeout="-1"
        color="warning"
      >
        No está conectado a internet.
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
// import { liveQuery } from 'dexie';
// import { db } from './localdb/db';

import { mapGetters } from 'vuex';

import CloseSesionDialog from '@/components/shared/CloseSesionDialog.vue';
import Snackbar from '@/components/shared/Snackbar.vue';
import gql from 'graphql-tag';

export default {
  components: { snackbar: Snackbar, 'close-sesion-dialog': CloseSesionDialog },

  watch: {
    $route: {
      inmediate: true,
      handler() {
        document.title = `${this.$router.currentRoute.meta.title} - ${process.env.VUE_APP_TITLE}`;
        this.sectionTitle = this.$router.currentRoute.meta.title;
      },
    },
  },

  data: () => ({
    isOnline: false,

    installBtn: false,
    installer: undefined,
    refreshing: false,
    registration: null,
    updateExists: false,

    drawer: null,
    selectedItem: 0,
    sectionTitle: '',

    appUser: {},

    showPopupCloseSesion: false,

    leftMenu: [],

    paths: {
      users: {
        name: 'Usuarios',
        icon: 'mdi-account-multiple',
        to: '/usuarios',
      },
      towns: {
        name: 'Municipios',
        icon: 'mdi-map-marker-multiple-outline',
        to: '/municipios',
      },
      locations: {
        name: 'Veredas',
        icon: 'mdi-map-legend',
        to: '/veredas',
      },
      products: { name: 'Productos', icon: 'mdi-leaf', to: '/productos' },
      crops: {
        name: 'Cultivos',
        icon: 'mdi-sprout',
        to: '/cultivos',
      },
      associations: {
        name: 'Asociaciones',
        icon: 'mdi-handshake',
        to: '/asociaciones',
      },
      farmers: {
        name: 'Productores',
        icon: 'mdi-account-cowboy-hat',
        to: '/productores',
      },
      support: {
        name: 'Asistencia técnica',
        icon: 'mdi-format-list-checks',
        to: '/asistencia',
      },
      visits: {
        name: 'Visitas',
        icon: 'mdi-home-silo-outline',
        to: '/visitas',
      },
      school: {
        name: 'Escuela de técnicos',
        icon: 'mdi-school-outline',
        to: '/escuela',
      },
    },

    profileMenu: [
      { title: 'Perfil', to: '/perfil' },
      { title: 'Cerrar sesión', click: 'openCloseSesionPopup' },
    ],
  }),

  async created() {
    // Saber si hay conexión o no
    this.hasInternetConnection();
    window.addEventListener('online', this.hasInternetConnection);
    window.addEventListener('offline', this.hasInternetConnection);

    //FIXME: hacer la desuscripción cuando se destruya el componente
    /* // Es un observable con Dexie
    const messagesObservable = liveQuery(() => db.message.toArray());
    // const subscription = messagesObservable...
    // subscription.unsubscribe
    messagesObservable.subscribe({
      next: (result) => console.log('Traigo', JSON.stringify(result)),
      error: (error) => console.error(error),
    });
    */

    // Para mostrar mensaje de instalación
    let installPromp;

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      installPromp = e;
      this.installBtn = true;

      this.installer = () => {
        this.installBtn = false;
        installPromp.prompt();
        installPromp.userChoice.then(() => {
          /*
          if (choice.outcome == 'accepted') {
            console.log('user accepted!!');
          } else {
            console.log('user denied');
          }
          */

          installPromp = null;
        });
      };
    });

    // Para mostrar mensaje de actualización
    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true,
    });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }

    // Traer datos de la app
    await this.getAppUser();

    if (this.$router.currentRoute.fullPath == '/') {
      if (this.userRole == 'TECHNICIAN') {
        this.$router.push({ name: 'visitas' });
      } else {
        this.$router.push({ name: 'asistencia' });
      }
    }

    this.leftMenu = [];

    if (this.userRole == 'FARMER') {
      this.leftMenu.push(
        this.paths.products,
        this.paths.crops,
        this.paths.support,
        this.paths.school
      );
    }

    if (this.userRole == 'ADMIN') {
      this.leftMenu.push(
        this.paths.users,
        this.paths.towns,
        this.paths.locations,
        this.paths.products,
        this.paths.associations,
        this.paths.farmers,
        this.paths.school,
        this.paths.support
      );
    }

    if (this.userRole == 'AGRONOMIST') {
      this.leftMenu.push(this.paths.school, this.paths.support);
    }

    if (this.userRole == 'TECHNICIAN') {
      this.leftMenu.push(this.paths.visits);
    }

    document.title = `${this.$router.currentRoute.meta.title} - ${process.env.VUE_APP_TITLE}`;
    this.sectionTitle = this.$router.currentRoute.meta.title;
  },

  methods: {
    hasInternetConnection() {
      this.isOnline = navigator.onLine;
      this.$store.commit('network/setState', { connected: this.isOnline });
    },

    updateAvailable(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },

    refreshApp() {
      this.updateExists = false;

      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ action: 'skipWaiting' });
    },

    openCloseSesionPopup() {
      this.showPopupCloseSesion = true;
    },

    closeCloseSesionPopup(payload) {
      this.showPopupCloseSesion = false;

      if (payload) {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        window.location.replace('/auth');
      }
    },

    async getAppUser() {
      const query = gql`
        query getAppUser {
          getAppUser {
            name
            role
          }
        }
      `;

      const {
        data: { getAppUser },
      } = await this.$apollo.query({
        query,
        fetchPolicy: 'network-only',
      });

      this.appUser.name = getAppUser.name;

      this.$store.commit('userData/setData', {
        role: getAppUser.role,
      });
    },
  },

  computed: {
    ...mapGetters({ userRole: 'userData/userRole' }),
  },
};
</script>

<style scoped lang="sass">
.bg-active
  background: #33913B
  color: #fff !important

.bg-gradient
  background-color: #d9d9d9
  background: linear-gradient(360deg, #dee1e1 10%, #f4f4f4 360%)
</style>
