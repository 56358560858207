<template>
  <div>
    <school-others-view
      v-if="userRole && (userRole == 'ADMIN' || userRole == 'FARMER')"
    />
    <school-agronomist-view v-if="userRole && userRole == 'AGRONOMIST'" />
  </div>
</template>

<script>
import SchoolOthersView from '@/components/school/SchoolOthersView.vue';
import SchoolAgronomistView from '@/components/school/SchoolAgronomistView.vue';

import { mapGetters } from 'vuex';

export default {
  components: {
    SchoolOthersView,
    SchoolAgronomistView,
  },

  data: () => ({}),

  computed: {
    ...mapGetters({ userRole: 'userData/userRole' }),
  },
};
</script>
