export default {
  namespaced: true,
  name: 'snackbar',
  state: {
    snack: {},
  },

  mutations: {
    setSnack(state, snackData) {
      state.snack = { ...snackData };
    },

    resetState(state) {
      Object.assign(state, {});
    },
  },
};
