var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.tableSchoolArticles,"options":_vm.options,"server-items-length":_vm.totalSchoolArticles,"items-per-page":12,"footer-props":{
      'disable-items-per-page': true,
      itemsPerPageOptions: [12],
    },"no-data-text":"No hay información publicada"},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.dialog)?_c('v-dialog',{attrs:{"max-width":"600px","persistent":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-button grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.selectedItem.title)+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"rounded-sm grey lighten-3",attrs:{"src":_vm.selectedItem.image},on:{"click":function($event){return _vm.openLightbox(0)}}}),_c('vue-easy-lightbox',{attrs:{"maskClosable":false,"visible":_vm.showLightbox,"imgs":[
                      {
                        title: 'Infografía',
                        src: _vm.selectedItem.image,
                      },
                    ],"index":_vm.imageIndex},on:{"hide":function($event){_vm.showLightbox = false}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.selectedItem.info)+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cerrar ")])],1),_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"48"}})],1)],1)],1):_vm._e()]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openDatasheet(item)}}},[_vm._v(" mdi-eye ")])]}},{key:"footer.prepend",fn:function(){return [_c('v-spacer',{staticClass:"py-6"})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }