<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="productsFarmer"
      :options.sync="options"
      :server-items-length="totalProducts"
      :items-per-page="12"
      :footer-props="{
        'disable-items-per-page': true,
        itemsPerPageOptions: [12],
      }"
      @page-count="pageCount = $event"
      no-data-text="No tiene productos registrados"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />

          <v-btn color="primary" fab x-small @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-dialog
              v-model="dialog"
              v-if="dialog"
              max-width="600px"
              persistent
              scrollable
            >
              <v-form @submit.prevent="submitForm">
                <v-card>
                  <v-card-title>
                    <span class="text-button grey--text text--darken-1"
                      >Registrar producto/s</span
                    >
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el producto"
                          >
                            <v-autocomplete
                              v-model="selectedItem.products"
                              dense
                              eager
                              clearable
                              multiple
                              small-chips
                              deletable-chips
                              hide-selected
                              label="Producto/s"
                              :error-messages="errors"
                              :items="productsAvailable"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>*Indica campo requerido</small>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />

                    <v-btn color="grey darken-1" text @click="close">
                      Cancelar
                    </v-btn>

                    <v-btn
                      type="submit"
                      color="green darken-2"
                      :disabled="invalid"
                      text
                    >
                      Registrar producto/s
                    </v-btn>
                  </v-card-actions>

                  <v-overlay :value="overlay" absolute>
                    <v-progress-circular indeterminate size="48" />
                  </v-overlay>
                </v-card>
              </v-form>
            </v-dialog>
          </ValidationObserver>

          <v-dialog v-model="dialogDelete" max-width="600px" persistent>
            <v-card>
              <v-card-title
                class="grey--text text--darken-1 font-weight-regular text-subtitle-1"
              >
                ¿Está seguro que desea eliminar el producto
                <b>&nbsp;{{ selectedItem.text }} </b>?
              </v-card-title>

              <v-card-text>
                La información no podrá ser recuperada.
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="grey darken-1" text @click="closeDelete">
                  Cancelar
                </v-btn>
                <v-btn color="red darken-1" text @click="deleteItemConfirm">
                  Eliminar producto
                </v-btn>
              </v-card-actions>

              <v-overlay :value="overlay" absolute>
                <v-progress-circular indeterminate size="48" />
              </v-overlay>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template #footer.prepend>
        <v-spacer class="py-6" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

export default {
  name: 'ProductsFarmerView',

  components: { ValidationProvider, ValidationObserver },

  async mounted() {
    const query = gql`
      query GetAllProducts {
        getAllProducts {
          value: id
          text: name
        }
      }
    `;

    const {
      data: { getAllProducts },
    } = await this.$apollo
      .query({ query, fetchPolicy: 'network-only' })
      .catch((error) => {
        this.overlay = false;

        this.restrictedAction(error);
        return;
      });

    this.productsList = getAllProducts;
  },

  mixins: [restrictedActionMixin],

  data: () => ({
    overlay: false,

    dialog: false,
    dialogDelete: false,
    loading: false,
    options: {},
    totalProducts: 0,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Producto',
        align: 'start',
        sortable: false,
        value: 'text',
      },
      { text: 'Acciones', sortable: false, value: 'actions', align: 'end' },
    ],
    productsFarmer: [], // Productos asociados al productor
    productsList: [], // Todos los productos registrados
    selectedItem: {
      value: '',
      text: '',
      products: [], // Productos seleccionados
    },
  }),

  computed: {
    productsAvailable() {
      const productsFarmer = this.productsFarmer.map((item) =>
        JSON.stringify(item)
      );

      const productsList = this.productsList.map((item) =>
        JSON.stringify(item)
      );

      const productsAvailable = [];

      productsList.map((item) => {
        if (!productsFarmer.includes(item)) {
          productsAvailable.push(JSON.parse(item));
        }
      });

      return productsAvailable;
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async initialize() {
      this.loading = true;
      const query = gql`
        query getProductsPaginated($input: GetProductsPaginatedInput!) {
          getProductsPaginated(input: $input) {
            products {
              value: id
              text: name
            }

            total
          }
        }
      `;

      const {
        data: { getProductsPaginated },
      } = await this.$apollo
        .query({
          query,
          variables: {
            input: {
              page: this.options.page,
              perPage: this.options.itemsPerPage,
            },
          },
          fetchPolicy: 'network-only',
        })
        .catch((error) => {
          this.loading = false;

          this.restrictedAction(error);
          return;
        });

      this.productsFarmer = getProductsPaginated.products;
      this.totalProducts = getProductsPaginated.total;

      this.loading = false;
    },

    deleteItem(item) {
      this.selectedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.overlay = true;

      const mutation = gql`
        mutation deleteProduct($input: DeleteProductInput) {
          deleteProduct(input: $input) {
            success
            message
          }
        }
      `;

      const {
        data: { deleteProduct },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: {
            input: {
              id: this.selectedItem.value,
            },
          },
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      this.overlay = false;

      if (deleteProduct.success) {
        this.initialize();
        this.closeDelete();
      }

      this.$store.commit('snackbar/setSnack', {
        message: deleteProduct.message,
        color: deleteProduct.success ? 'success' : 'error',
      });
    },

    close() {
      this.dialog = false;
      this.selectedItem = {};
      this.$nextTick(() => {});
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {});
    },

    async submitForm() {
      this.overlay = true;

      delete this.selectedItem.id;
      delete this.selectedItem.text;

      const mutation = gql`
        mutation relateProducts($input: RelateProductsInput) {
          relateProducts(input: $input) {
            success
            message
          }
        }
      `;

      const {
        data: { relateProducts },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: { products: this.selectedItem.products } },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      if (relateProducts.success) {
        this.initialize();

        this.close();
      }

      this.$store.commit('snackbar/setSnack', {
        message: relateProducts.message,
        color: relateProducts.success ? 'success' : 'error',
      });

      this.overlay = false;
    },
  },
};
</script>
