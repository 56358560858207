import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from 'apollo-boost';

import { onError } from 'apollo-link-error';

import VueApollo from 'vue-apollo';
import './registerServiceWorker';

Vue.config.productionTip = false;

const origin = window.location.origin;

// Para poder identificar si está en local o en producción
const uri =
  window.location.hostname == 'localhost' ? 'http://localhost:3000' : origin;

Vue.use(VueApollo);

// Añadir automáticamente los tokens
const setTokensMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'x-token': localStorage.getItem('token') || null,
      'x-refresh': localStorage.getItem('refresh') || null,
    },
  });

  return forward(operation);
});

// Guardar los nuevos tokens cuando hayan expirado los enviados
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const token = context.response.headers.get('x-token');
    const refresh = context.response.headers.get('x-refresh');

    if (token && refresh) {
      localStorage.setItem('token', token);
      localStorage.setItem('refresh', refresh);
    }

    return response;
  });
});

// INFO: documentación
// https://www.apollographql.com/docs/react/networking/advanced-http-networking/
// https://www.apollographql.com/docs/react/v2/migrating/boost-migration/
const errorLink = onError((error) => {
  // console.log('putiao', error);
  if (error.networkError.statusCode === 401) {
    localStorage.setItem('token', null);
    localStorage.setItem('refresh', null);
    // Con replace se evita que el usuario pueda presionar el botón de atrás del navegador
    window.location.replace('/auth');
    // window.location.href = '/auth';
  }
});

const httpLink = new HttpLink({ uri: uri + '/api' });

const apolloClient = new ApolloClient({
  // link: afterwareLink.concat(httpLink),
  link: ApolloLink.from([
    afterwareLink,
    errorLink,
    setTokensMiddleware,
    httpLink,
  ]),
  cache: new InMemoryCache({
    addTypename: false,
  }) /*,
  onError: ({ networkError }) => {
    console.log(networkError);
    if (networkError.statusCode === 401) {
      alert('pa juera!');
    }
  },*/,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
