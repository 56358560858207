<template>
  <v-snackbar
    v-model="show"
    :color="color"
    timeout="5000"
    top
    elevation="2"
    style="z-index: 9999"
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn icon small v-bind="attrs" @click.native="show = false">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',

  data: () => ({
    show: false,
    message: '',
    color: '',
  }),

  created() {
    this.$store.watch(
      (state) => state.snackbar.snack,
      () => {
        const message = this.$store.state.snackbar.snack.message;
        if (message) {
          this.show = true;
          this.message = message;

          this.color = this.$store.state.snackbar.snack.color;
          this.$store.commit('snackbar/setSnack', {});
        }
      }
    );
  },
};
</script>
