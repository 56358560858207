<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <v-form @submit.prevent="submitForm" ref="form">
      <v-card outlined max-width="600">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="su nombre"
                >
                  <v-text-field
                    v-model="formData.name"
                    dense
                    clearable
                    label="Nombre/s*"
                    :error-messages="errors"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="su apellido"
                >
                  <v-text-field
                    v-model="formData.lastname"
                    dense
                    clearable
                    label="Apellidos/s*"
                    :error-messages="errors"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="el tipo de documento"
                >
                  <v-select
                    v-model="formData.documentType"
                    :items="[
                      { text: 'Cédula Ciudadanía', value: 'CC' },
                      { text: 'NIT', value: 'NIT' },
                      { text: 'Cédula Extranjería', value: 'CE' },
                    ]"
                    dense
                    clearable
                    label="Tipo de documento*"
                    :error-messages="errors"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|numeric|min:7"
                  name="el documento"
                >
                  <v-text-field
                    v-model="formData.documentNumber"
                    dense
                    clearable
                    label="Número documento*"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="numeric|digits:10|required"
                  name="el celular"
                >
                  <v-text-field
                    v-model="formData.mobile"
                    dense
                    clearable
                    label="Celular*"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="el municipio"
                >
                  <v-autocomplete
                    v-model="formData.townId"
                    :items="towns"
                    :loading="isLoadingTowns"
                    :disabled="isDisabledTowns"
                    dense
                    clearable
                    label="Municipio*"
                    :error-messages="errors"
                    required
                    @change="townAutoCompleteChange"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="la vereda"
                >
                  <v-autocomplete
                    v-model="formData.locationId"
                    :items="locations"
                    :loading="isLoadingLocations"
                    :disabled="isDisabledLocations"
                    dense
                    clearable
                    label="Vereda*"
                    :error-messages="errors"
                    required
                    @change="locationAutoCompleteChange"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="la asociación"
                >
                  <v-autocomplete
                    v-model="formData.associationId"
                    :items="associations"
                    :loading="isLoadingAssociations"
                    :disabled="isDisabledAssociations"
                    dense
                    clearable
                    label="Asociación*"
                    :error-messages="errors"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider v-slot="{ errors }" rules="" name="">
                  <v-text-field
                    v-model="formData.farmName"
                    dense
                    clearable
                    label="Nombre finca"
                    :error-messages="errors"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="email|required"
                  name="el correo"
                >
                  <v-text-field
                    v-model="formData.email"
                    dense
                    clearable
                    label="Correo electrónico*"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
          <small>*Indica campo requerido</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" color="green darken-2" :disabled="invalid" text>
            Guardar datos
          </v-btn>
        </v-card-actions>

        <v-overlay :value="overlay" absolute>
          <v-progress-circular indeterminate size="48" />
        </v-overlay>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import gql from 'graphql-tag';

import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required, email, numeric, min, digits } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

extend('email', {
  ...email,
  message: 'Debe ingresar un correo válido',
});

extend('numeric', {
  ...numeric,
  message: 'Ingrese sólo números',
});

extend('min', {
  ...min,
  message: 'Ingrese por lo menos {length} números',
});

extend('digits', {
  ...digits,
  message: 'Ingrese 10 dígitos',
});

export default {
  name: 'PersonalDataFarmers',

  components: { ValidationProvider, ValidationObserver },

  mixins: [restrictedActionMixin],

  data: () => ({
    overlay: false,

    isDisabledTowns: true,
    isLoadingTowns: false,
    isDisabledLocations: true,
    isLoadingLocations: false,
    isDisabledAssociations: true,
    isLoadingAssociations: false,

    formData: {},

    towns: [],
    locations: [],
    associations: [],
  }),

  //FIXME: si cambio algo en el formulario, cambio de sección y regreso, aunque tenga el policy la info no se restablece

  async created() {
    this.overlay = true;

    let query = gql`
      query GetAllTowns {
        getAllTowns {
          value: id
          text: name
        }
      }
    `;

    const {
      data: { getAllTowns },
    } = await this.$apollo
      .query({
        query,
        fetchPolicy: 'network-only',
      })
      .catch((error) => {
        this.overlay = false;

        this.restrictedAction(error);
        return;
      });

    this.towns = getAllTowns;

    this.isDisabledTowns = false;

    query = gql`
      query getFarmer {
        getFarmer {
          name
          lastname
          documentType
          documentNumber
          mobile
          townId
          locationId
          associationId
          farmName
          email
        }
      }
    `;

    const {
      data: { getFarmer },
    } = await this.$apollo
      .query({
        query,
        fetchPolicy: 'network-only',
      })
      .catch((error) => {
        this.overlay = false;

        this.restrictedAction(error);
        return;
      });

    this.formData = getFarmer;

    // Cargo las veredas basado en el municipio del productor
    await this.getAllLocationsByTown();

    // Cargo las asociaciones basado en el municipio del productor
    await this.getAllAssociationsByTown();

    this.overlay = false;
  },

  methods: {
    clearAssociationSelect() {
      this.formData.associationId = '';
      this.associations = [];
      this.isDisabledAssociations = true;
    },

    async townAutoCompleteChange() {
      if (!this.formData.townId) {
        this.formData.locationId = '';
        this.locations = [];
        this.isDisabledLocations = true;

        this.clearAssociationSelect();

        this.$refs.observer.reset();

        return;
      }

      this.getAllLocationsByTown();

      this.clearAssociationSelect();
    },

    async getAllLocationsByTown() {
      this.isLoadingLocations = true;

      const query = gql`
        query getAllLocationsByTown($input: GetAllLocationsByTownInput) {
          getAllLocationsByTown(input: $input) {
            value: id
            text: name
          }
        }
      `;

      const {
        data: { getAllLocationsByTown },
      } = await this.$apollo.query({
        query,
        variables: {
          input: {
            townId: this.formData.townId,
          },
        },
        fetchPolicy: 'network-only',
      });

      this.locations = getAllLocationsByTown;

      this.isLoadingLocations = false;
      this.isDisabledLocations = false;
    },

    async locationAutoCompleteChange() {
      if (!this.formData.locationId) {
        this.formData.associationId = '';
        this.associations = [];
        this.isDisabledAssociations = true;

        this.$refs.observer.reset();

        return;
      }

      this.getAllAssociationsByTown();
    },

    async getAllAssociationsByTown() {
      this.isLoadingAssociations = true;

      const query = gql`
        query getAllAssociationsByTown($input: getAllAssociationsByTownInput) {
          getAllAssociationsByTown(input: $input) {
            value: id
            text: name
          }
        }
      `;

      const {
        data: { getAllAssociationsByTown },
      } = await this.$apollo.query({
        query,
        variables: {
          input: {
            townId: this.formData.townId,
          },
        },
        fetchPolicy: 'network-only',
      });

      this.associations = getAllAssociationsByTown;
      this.associations.push({ text: 'No asociado', value: 'not_associated' });

      this.isLoadingAssociations = false;
      this.isDisabledAssociations = false;
    },

    async submitForm() {
      this.overlay = true;

      const variables = { ...this.formData };

      if (variables.associationId == 'not_associated') {
        delete variables.associationId;
      }

      const mutation = gql`
        mutation editFarmer($input: DataFarmerInput) {
          editFarmer(input: $input) {
            success
            message
          }
        }
      `;

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      this.overlay = false;

      if (data.editFarmer.success) {
        this.$store.commit('snackbar/setSnack', {
          message: data.editFarmer.message,
          color: 'success',
        });
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: data.editFarmer.message,
          color: 'error',
        });
      }
    },
  },
};
</script>
