<template>
  <div>
    <v-row class="mt-0">
      <v-col cols="12" class="d-flex justify-space-between align-center">
        <span class="d-inline-flex" v-if="userRole != 'AGRONOMIST'">
          <v-icon
            small
            :color="this.isActive ? 'primary' : 'grey'"
            class="mr-1"
          >
            mdi-checkbox-marked-circle
          </v-icon>
          {{ this.isActive ? 'Activa' : 'Inactiva' }}
        </span>

        <v-switch
          v-else
          v-model="isActive"
          color="primary"
          class="mt-0 ml-2"
          dense
          inset
          :label="isActive ? 'Activa' : 'Inactiva'"
          hide-details
          @change="switchActiveChange"
        />

        <v-btn
          v-if="userRole == 'AGRONOMIST'"
          small
          outlined
          color="primary"
          :disabled="!isActive"
          @click="dialogRecommendation = true"
        >
          {{ formActionBtn }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          :value="`${support.farmer.name} ${support.farmer.lastname}`"
          label="Nombre del productor"
          dense
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          :value="support.date"
          label="Fecha solicitud"
          dense
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          :value="support.crop.product.name"
          label="Cultivo"
          dense
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          :value="support.crop.date"
          label="Fecha siembra"
          dense
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          :value="support.crop.qty"
          label="Cantidad"
          dense
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          :value="
            support.crop.measurementType == 'HECTARES' ? 'Hectáreas' : 'Plantas'
          "
          label="Unidad de medida"
          dense
          readonly
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-img
          class="rounded-sm grey lighten-3"
          height="140"
          :src="support.cropGeneralPhoto"
          @click="openLightbox(0)"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-2" />
            </v-row>
          </template>
        </v-img>
        <div class="subheading">Foto cultivo</div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-img
          class="rounded-sm grey lighten-3"
          height="140"
          :src="support.cropCloseUpPhoto"
          @click="openLightbox(1)"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-2" />
            </v-row>
          </template>
        </v-img>
        <div class="subheading">Foto daño</div>
        <vue-easy-lightbox
          :maskClosable="false"
          :visible="showLightbox"
          :imgs="[
            {
              title: 'Foto cultivo',
              src: support.cropGeneralPhoto,
            },
            {
              title: 'Foto daño',
              src: support.cropCloseUpPhoto,
            },
          ]"
          :index="imageIndex"
          @hide="showLightbox = false"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          :value="support.situation"
          dense
          auto-grow
          rows="1"
          label="Descripción del problema"
          readonly
        />
      </v-col>
      <v-col cols="12">
        <div class="caption">Productos aplicados</div>
        <p v-if="support.appliedProducts.length == 0" class="mb-0">
          No se aplicaron productos
        </p>
        <v-simple-table dense v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nombre</th>
                <th class="text-left">Dosis</th>
                <th class="text-left">Volumen</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(product, index) in support.appliedProducts"
                :key="index"
              >
                <td>{{ product.name }}</td>
                <td>{{ product.dosage }}</td>
                <td>{{ product.volume }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12">
        <div class="caption">Recomendaciones</div>
        <p v-if="support.recommendations.length == 0" class="mb-0">
          No se realizaron recomendaciones
        </p>
        <div id="recommendations">
          <v-row
            style="position: relative"
            fluid
            v-for="(recommendation, index) in support.recommendations"
            :key="index"
          >
            <v-btn
              v-if="userRole == 'AGRONOMIST'"
              style="background-color: #ffffff"
              class="mt-2"
              x-small
              fab
              absolute
              top
              right
              outlined
              elevation="1"
              color="primary"
              :disabled="!isActive"
              @click="editItem(recommendation)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-col cols="12">
              <v-textarea
                :value="recommendation.description"
                :hint="recommendation.date"
                persistent-hint
                dense
                auto-grow
                rows="1"
                readonly
              />
            </v-col>
            <v-col cols="12">
              <div class="caption">Productos recomendados</div>
              <p
                v-if="recommendation.recommendedProducts.length == 0"
                class="mb-0"
              >
                No se recomendaron productos
              </p>
              <v-simple-table dense v-else>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Nombre</th>
                      <th class="text-left">Ingrediente Activo</th>
                      <th class="text-left">Dosis</th>
                      <th class="text-left">Cant. Agua</th>
                      <th class="text-left">Período carencia</th>
                      <th class="text-left">Período reingreso</th>
                      <th class="text-left">Aplicado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(product, i) in recommendation.recommendedProducts"
                      :key="i"
                    >
                      <td>{{ product.name }}</td>
                      <td>{{ product.activeIngredient }}</td>
                      <td>{{ product.dosage }}</td>
                      <td>{{ product.waterQty }}</td>
                      <td>{{ product.waitingPeriod }}</td>
                      <td>{{ product.reEntryPeriod }}</td>
                      <td>
                        <v-checkbox
                          dense
                          hide-details
                          readonly
                          class="mt-0 pt-0"
                          v-model="recommendedProductsApplied['p' + index][i]"
                          :value="i"
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="7">
                        {{ recommendation.productsObservations }}
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-if="userRole == 'AGRONOMIST' && dialogConfirm"
      v-model="dialogConfirm"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title
          class="grey--text text--darken-1 font-weight-regular text-subtitle-1"
        >
          ¿Confirma que desea
          <b>&nbsp;{{ isActive ? 'activar' : 'desactivar' }}&nbsp;</b> la
          asistencia ?
        </v-card-title>

        <v-card-text> </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="supportStatusCancel">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="supportStatusConfirm">
            {{ isActive ? 'activar' : 'desactivar' }} asistencia
          </v-btn>
        </v-card-actions>

        <v-overlay :value="overlayActivation" absolute>
          <v-progress-circular indeterminate size="48" />
        </v-overlay>
      </v-card>
    </v-dialog>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-dialog
        v-if="userRole == 'AGRONOMIST' && dialogRecommendation"
        v-model="dialogRecommendation"
        max-width="600px"
        persistent
        scrollable
      >
        <v-form @submit.prevent="submitForm">
          <v-card>
            <v-card-title>
              <span class="text-button grey--text text--darken-1">
                {{ formTitle }}
              </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="la recomendación"
                    >
                      <v-textarea
                        v-model="selectedItem.description"
                        dense
                        auto-grow
                        rows="3"
                        clearable
                        label="Recomendación*"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <v-row justify="space-between" align="center" no-gutters>
                      <v-switch
                        dense
                        inset
                        label="¿Recomendar productos?"
                        v-model="selectedItem.wereProductsToRecommend"
                      />
                      <v-btn
                        v-if="selectedItem.wereProductsToRecommend"
                        color="primary"
                        fab
                        x-small
                        @click="productsToRecommend.push({})"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                  <div
                    id="products"
                    v-if="selectedItem.wereProductsToRecommend"
                  >
                    <v-container
                      fluid
                      v-for="(row, index) in productsToRecommend"
                      :key="index"
                    >
                      <v-row align="center">
                        <v-col cols="12" sm="4">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el nombre del producto"
                          >
                            <v-text-field
                              v-model="productsToRecommend[index]['name']"
                              dense
                              clearable
                              label="Nombre producto*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el ingrediente activo"
                          >
                            <v-text-field
                              v-model="
                                productsToRecommend[index]['activeIngredient']
                              "
                              dense
                              clearable
                              label="Ingrediente Activo*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="la dosis"
                          >
                            <v-text-field
                              v-model="productsToRecommend[index]['dosage']"
                              dense
                              clearable
                              label="Dosis*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="10" sm="4">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="la cantidad de agua"
                          >
                            <v-text-field
                              v-model="productsToRecommend[index]['waterQty']"
                              dense
                              clearable
                              label="Cantidad de agua*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="10" sm="4">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el período de carencia"
                          >
                            <v-text-field
                              v-model="
                                productsToRecommend[index]['waitingPeriod']
                              "
                              dense
                              clearable
                              label="Período de carencia*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="10" sm="3">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el período de reentrada"
                          >
                            <v-text-field
                              v-model="
                                productsToRecommend[index]['reEntryPeriod']
                              "
                              dense
                              clearable
                              label="Período de reentrada*"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="2" sm="1" v-if="index > 0">
                          <v-btn
                            color="error"
                            icon
                            @click="removeProduct(index)"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-col cols="12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules=""
                        name="las observaciones"
                      >
                        <v-textarea
                          v-model="selectedItem.productsObservations"
                          dense
                          auto-grow
                          rows="3"
                          clearable
                          label="Observaciones producto(s)"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                  </div>
                </v-row>
              </v-container>
              <small>*Indica campo requerido</small>
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="grey darken-1"
                text
                @click="closeRecommendationDialog"
              >
                Cancelar
              </v-btn>

              <v-btn
                type="submit"
                color="green darken-2"
                :disabled="invalid"
                text
              >
                {{ formActionBtn }}
              </v-btn>
            </v-card-actions>

            <v-overlay :value="overlayRecommendation" absolute>
              <v-progress-circular indeterminate size="48" />
            </v-overlay>
          </v-card>
        </v-form>
      </v-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import VueEasyLightbox from 'vue-easy-lightbox';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('es');
dayjs.extend(localizedFormat);

import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import { mapGetters } from 'vuex';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

export default {
  name: 'SupportSheetTab',

  components: {
    VueEasyLightbox,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [restrictedActionMixin],

  props: ['supportId'],

  created() {
    this.initialize();
  },

  data: () => ({
    showLightbox: false,
    imageIndex: 0,

    productsToRecommend: [{}],

    support: {
      farmer: {},
      crop: {
        product: {},
      },
      appliedProducts: [],
      recommendations: [],
    }, // Se construye la estructura para evitar errores de render

    recommendedProductsApplied: {},

    selectedIndex: -1,
    selectedItem: {},
    defaultItem: {},

    dialogConfirm: false,
    overlayActivation: false,

    dialogRecommendation: false,
    overlayRecommendation: false,
  }),

  methods: {
    openLightbox(index) {
      this.imageIndex = index;
      this.showLightbox = true;
    },

    switchActiveChange() {
      this.dialogConfirm = true;
    },

    async supportStatusConfirm() {
      this.$emit('loading', true);

      const variables = {
        supportId: this.supportId,
        status: this.support.status,
      };

      const mutation = gql`
        mutation changeSupportStatus($input: DataChangeSupportStatusInput) {
          changeSupportStatus(input: $input) {
            success
            message
          }
        }
      `;

      const {
        data: { changeSupportStatus },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
        })
        .catch((error) => {
          this.$emit('loading', false);

          this.restrictedAction(error);
          return;
        });

      this.$store.commit('snackbar/setSnack', {
        message: changeSupportStatus.message,
        color: changeSupportStatus.success ? 'success' : 'error',
      });

      this.$emit('loading', false);
      this.dialogConfirm = false;
    },

    supportStatusCancel() {
      this.dialogConfirm = false;
      this.isActive = !this.isActive;
    },

    closeRecommendationDialog() {
      this.dialogRecommendation = false;
      this.productsToRecommend = [{}];
      this.selectedItem.wereProductsToRecommend = false;
    },

    editItem({ id }) {
      this.selectedIndex = this.support.recommendations.findIndex(
        (item) => item.id == id
      );
      this.selectedItem = Object.assign(
        {},
        this.support.recommendations[this.selectedIndex]
      );
      this.dialogRecommendation = true;

      if (this.selectedItem.recommendedProducts.length > 0) {
        const productsToRecommend = [];
        this.selectedItem.recommendedProducts.map((product, index) => {
          productsToRecommend[index] = {
            name: product.name,
            activeIngredient: product.activeIngredient,
            dosage: product.dosage,
            waterQty: product.waterQty,
            waitingPeriod: product.waitingPeriod,
            reEntryPeriod: product.reEntryPeriod,
          };
        });

        this.productsToRecommend = productsToRecommend;

        // Se asigna con $set para que el valor sea reactivo y tanto el botón como la lista se comporten como debe
        this.$set(this.selectedItem, 'wereProductsToRecommend', true);
      }
    },

    removeProduct(index) {
      this.productsToRecommend.splice(index, 1);
    },

    async initialize() {
      this.$emit('loading', true);

      const query = gql`
        query GetSupport($input: GetSupportInput) {
          getSupport(input: $input) {
            date
            cropGeneralPhoto
            cropCloseUpPhoto
            situation
            status

            crop {
              date
              qty
              measurementType

              product {
                name
              }
            }

            farmer {
              name
              lastname
            }

            appliedProducts {
              name
              volume
              dosage
            }

            recommendations {
              id
              date
              description
              productsObservations

              recommendedProducts {
                name
                activeIngredient
                dosage
                waterQty
                waitingPeriod
                reEntryPeriod
                status
              }
            }

            # technician: user {
            #   name
            #   lastname
            # }
          }
        }
      `;

      const {
        data: { getSupport },
      } = await this.$apollo
        .query({
          query,
          variables: { input: { supportId: this.supportId } },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.restrictedAction(error);

          this.$emit('loading', false);
          this.$emit('closeDialog');

          return;
        });

      this.recommendedProductsApplied = [];
      getSupport.recommendations = getSupport.recommendations.map(
        (recommendation, index) => {
          // Llenar el array de si ahn sido o no aplicados los productos recomendados
          const areApplied = [];
          recommendation.recommendedProducts.map((product) => {
            const isApplied = product.status == 'APPLIED' ? 1 : 0;
            areApplied.push(isApplied);
          });

          if (areApplied.length > 0) {
            this.recommendedProductsApplied['p' + index] = areApplied;
          }

          recommendation.date = dayjs(recommendation.date).format('LL h:mm a');

          return recommendation;
        }
      );

      getSupport.date = dayjs(getSupport.date).format('LL h:mm a');
      getSupport.crop.date = dayjs(getSupport.crop.date).format('LL');

      this.support = getSupport;

      this.$emit('loading', false);
    },

    async submitForm() {
      this.overlayRecommendation = true;

      let mutation;
      const variables = this.selectedItem;

      if (this.selectedIndex > -1) {
        delete variables.date;
        // Editar recomendación
        mutation = gql`
          mutation editRecommendation($input: DataRecommendationInput) {
            editRecommendation(input: $input) {
              success
              message
            }
          }
        `;
      } else {
        // Añadir recomendación
        mutation = gql`
          mutation addRecommendation($input: DataRecommendationInput) {
            addRecommendation(input: $input) {
              success
              message
            }
          }
        `;

        variables.supportId = this.supportId;
      }

      if (this.selectedItem.wereProductsToRecommend) {
        variables.recommendedProducts = JSON.parse(
          JSON.stringify(this.productsToRecommend)
        );
      } else {
        variables.recommendedProducts = [];
      }

      delete variables.wereProductsToRecommend;

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
        })
        .catch((error) => {
          this.overlayRecommendation = false;

          this.restrictedAction(error);
          return;
        });

      let isSuccess, message;
      if (this.selectedIndex > -1) {
        isSuccess = data.editRecommendation.success;
        message = data.editRecommendation.message;
      } else {
        isSuccess = data.addRecommendation.success;
        message = data.addRecommendation.message;
      }

      if (isSuccess) {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'success',
        });

        this.initialize();

        this.close();
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'error',
        });
      }

      this.overlayRecommendation = false;
    },

    close() {
      this.dialogRecommendation = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },
  },

  computed: {
    ...mapGetters({ userRole: 'userData/userRole' }),

    isActive: {
      get() {
        return this.support.status == 'ACTIVE';
      },
      set(value) {
        this.support.status = value ? 'ACTIVE' : 'INACTIVE';
      },
    },

    formTitle() {
      return this.selectedIndex === -1
        ? 'Añadir recomendación'
        : 'Editar recomendación';
    },

    formActionBtn() {
      return this.selectedIndex === -1
        ? 'Añadir recomendación'
        : 'Editar recomendación';
    },
  },

  watch: {
    dialogRecommendation(val) {
      val || this.close();
    },
  },
};
</script>

<style lang="sass" scoped>
#products .container:nth-child(even)
  background: #f2f2f2

#recommendations
  .row:nth-child(even)
    background: #f2f2f2

    .caption
      margin-left: 8px

    .v-textarea
      padding: 0 8px

    .v-data-table
      background: transparent
</style>
