<template>
  <div>
    <div class="d-flex flex-column-reverse align-bottom justify-start">
      <div v-if="msgs.length == 0">No se han publicado mensajes.</div>
      <chat-bubble
        v-for="(msg, index) in msgs"
        :key="index"
        :message="msg"
        :userRole="userRole"
      />
    </div>
    <div v-if="userRole && (userRole == 'FARMER' || userRole == 'AGRONOMIST')">
      <v-divider class="mt-3" />
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <v-row no-gutters class="align-end">
          <v-col cols="11" id="no-error-messages">
            <ValidationProvider rules="required">
              <v-textarea
                v-model="msg"
                dense
                auto-grow
                clearable
                rows="1"
                placeholder="Escriba un mensaje"
                :disabled="status == 'INACTIVE'"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              class="ml-2"
              color="primary"
              :disabled="invalid"
              @click="sendMessage"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import { mapGetters } from 'vuex';

import ChatBubble from '@/components/support/supportTabs/ChatBubble.vue';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('es');
dayjs.extend(localizedFormat);

import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('eager');

export default {
  name: 'SupportMessageTab',

  components: {
    ValidationProvider,
    ValidationObserver,
    ChatBubble,
  },

  props: ['supportId', 'status'],

  async created() {
    this.$emit('loading', true);

    const query = gql`
      query GetMessages($input: GetMessagesInput) {
        getMessages(input: $input) {
          messages {
            date
            msg
            by
          }
        }
      }
    `;

    const {
      data: { getMessages },
    } = await this.$apollo
      .query({
        query,
        variables: { input: { supportId: this.supportId } },
        fetchPolicy: 'no-cache',
      })
      .catch((error) => {
        this.$emit('loading', false);

        this.restrictedAction(error);
        return;
      });

    this.msgs = getMessages.messages.map((msg) => {
      msg.date = dayjs(msg.date).format('LL h:mm a');

      return msg;
    });

    const divCardText = document.getElementById('divCardText');
    divCardText.scrollTop = divCardText.scrollHeight;

    this.$emit('loading', false);
  },

  mixins: [restrictedActionMixin],

  data: () => ({
    msgs: [],

    msg: '',
  }),

  methods: {
    async sendMessage() {
      const variables = {
        date: dayjs.tz().format('YYYY-MM-DDTHH:mm:ss'),
        msg: this.msg,
        by: this.userRole,
        supportId: this.supportId,
      };

      this.msgs.unshift({
        date: dayjs.tz().format('LL H:mm a'),
        msg: this.msg,
        by: this.userRole,
      });

      this.msg = '';

      const mutation = gql`
        mutation addMessage($input: DataMessageInput) {
          addMessage(input: $input) {
            success
            message
          }
        }
      `;

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.restrictedAction(error);
          this.msgs.shift();
          return;
        });

      if (data.addMessage.success) {
        //TODO: acá se debería notificar que se envió el mensaje
      }

      // console.log('sendTo', this.supportId);
    },
  },

  computed: {
    ...mapGetters({ userRole: 'userData/userRole' }),
  },
};
</script>

<style lang="sass">
#no-error-messages .v-text-field__details
  display: none
</style>
