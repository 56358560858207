<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <v-form @submit.prevent="submitForm">
      <v-card outlined max-width="600">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="el nombre del usuario"
                >
                  <v-text-field
                    v-model="formData.name"
                    dense
                    clearable
                    label="Nombre/s usuario*"
                    :error-messages="errors"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="el apellido del usuario"
                >
                  <v-text-field
                    v-model="formData.lastname"
                    dense
                    clearable
                    label="Apellidos/s usuario*"
                    :error-messages="errors"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="el tipo de documento"
                >
                  <v-select
                    v-model="formData.documentType"
                    :items="[
                      { text: 'Cédula Ciudadanía', value: 'CC' },
                      { text: 'NIT', value: 'NIT' },
                      { text: 'Cédula Extranjería', value: 'CE' },
                    ]"
                    dense
                    clearable
                    label="Tipo de
                    documento*"
                    :error-messages="errors"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|numeric|min:7"
                  name="el número de documento"
                >
                  <v-text-field
                    v-model="formData.documentNumber"
                    dense
                    clearable
                    label="Número documento*"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="la dirección"
                >
                  <v-text-field
                    v-model="formData.address"
                    dense
                    clearable
                    label="Dirección*"
                    :error-messages="errors"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="email|required"
                  name="el correo electrónico"
                >
                  <v-text-field
                    v-model="formData.email"
                    dense
                    clearable
                    label="Correo electrónico*"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="numeric|digits:10|required"
                  name="el celular"
                >
                  <v-text-field
                    v-model="formData.mobile"
                    dense
                    clearable
                    label="Celular*"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
          <small>*Indica campo requerido</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" color="green darken-2" :disabled="invalid" text>
            Guardar datos
          </v-btn>
        </v-card-actions>

        <v-overlay :value="overlay" absolute>
          <v-progress-circular indeterminate size="48" />
        </v-overlay>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import gql from 'graphql-tag';

import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required, email, numeric, min, digits } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

extend('email', {
  ...email,
  message: 'Debe ingresar un correo válido',
});

extend('numeric', {
  ...numeric,
  message: 'Ingrese sólo números',
});

extend('min', {
  ...min,
  message: 'Ingrese por lo menos {length} números',
});

extend('digits', {
  ...digits,
  message: 'Ingrese 10 dígitos',
});

export default {
  name: 'PersonalDataOthers',

  components: { ValidationProvider, ValidationObserver },

  mixins: [restrictedActionMixin],

  data: () => ({
    overlay: false,

    formData: {
      name: '',
      lastname: '',
      documentType: '',
      documentNumber: '',
      address: '',
      email: '',
      mobile: '',
    },
  }),

  //FIXME: si cambio algo en el formulario, cambio de sección y regreso, aunque tenga el policy la info no se restablece

  async created() {
    this.overlay = true;

    const query = gql`
      query getUser {
        getUser {
          name
          lastname
          documentType
          documentNumber
          address

          email
          mobile
          id
        }
      }
    `;

    const {
      data: { getUser },
    } = await this.$apollo
      .query({
        query,
        fetchPolicy: 'network-only',
      })
      .catch((error) => {
        this.overlay = false;

        this.restrictedAction(error);
        return;
      });

    this.formData = getUser;

    this.overlay = false;
  },

  methods: {
    async submitForm() {
      this.overlay = true;

      const mutation = gql`
        mutation editUser($input: DataUserInput) {
          editUser(input: $input) {
            success
            message
          }
        }
      `;

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: this.formData },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      this.overlay = false;

      if (data.editUser.success) {
        this.$store.commit('snackbar/setSnack', {
          message: data.editUser.message,
          color: 'success',
        });
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: data.editUser.message,
          color: 'error',
        });
      }
    },
  },
};
</script>

<style></style>
