/* eslint-disable no-console */

import { register } from 'register-service-worker';

/*const notifyUserAboutUpdate = (worker) => {
  const ok = confirm('¿Nueva actualización desea actualizar?');
  if (ok) {
    console.log('actualiza pe');
    worker.postMessage({ action: 'skipWaiting' });
  }
};*/

//FIXME: PROBANDO!!!! //Recibe mensaje desde el SW
/*
navigator.serviceWorker.addEventListener('message', function (event) {
  console.log('Received a message from service worker: ', event.data);
});
*/

if (process.env.NODE_ENV === 'production') {
  // register(`${process.env.BASE_URL}service-worker.js`, {
  register(`/service-worker.js`, {
    // registrationOptions: { scope: '/' },
    ready() {
      // console.log(
      //   'App is being served from cache by a service worker.\n' +
      //     'For more details, visit https://goo.gl/AFskqB'
      // );
      // FIXME: revisar si esta implementación está bien tenerla acá
      /* // Para notificaciones
      Notification.requestPermission((status) => {
        console.log('Status', status);
      });
      */
    },
    registered() {
      // Recibe el parámetro (registration)
      /*
      console.log('Service worker has been registered.');
      // Verficar en el intervalo de tiempo si hay una actualización
      setInterval(() => {
        registration.update();
      }, 5000); // every 5 seconds //FIXME: esto es opcional, para desarrollo. Según lo leído el navegador hace la verificación de manera periódica
      */
    },
    cached() {
      // console.log('Content has been cached for offline use.');
    },
    updatefound() {
      // console.log('New content is downloading.');
    },
    updated(registration) {
      // console.log('New content is available; please refresh.');
      // notifyUserAboutUpdate(registration.waiting);
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    },
    offline() {
      /*
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
      */
    },
    error() {
      // Recibe el parámetro (error)
      // console.error('Error during service worker registration:', error);
    },
  });

  /*
  let refreshing;
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('que recargues!!!');
    if (refreshing) return;
    console.log('estoy recargando');
    window.location.reload();
    refreshing = true;
  });
  */
}
