<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="products"
      :options.sync="options"
      :server-items-length="totalProducts"
      :items-per-page="12"
      :footer-props="{
        'disable-items-per-page': true,
        itemsPerPageOptions: [12],
      }"
      no-data-text="No hay productos registrados"
      @page-count="pageCount = $event"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />

          <v-btn color="primary" fab x-small @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-dialog
            v-model="cropsDialog"
            v-if="cropsDialog"
            max-width="600px"
            persistent
            scrollable
          >
            <v-card>
              <v-card-title>
                <span class="text-button grey--text text--darken-1">
                  Cultivos activos de {{ selectedItem.name }}
                </span>
              </v-card-title>

              <v-card-text>
                <v-row class="mt-0" v-for="(crop, index) in crops" :key="index">
                  <v-col cols="12">
                    <v-text-field
                      :value="crop.date"
                      label="Fecha siembra"
                      dense
                      readonly
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :value="crop.qty"
                      label="Cantidad"
                      dense
                      readonly
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :value="
                        crop.measurementType == 'HECTARES'
                          ? 'Hectáreas'
                          : 'Plantas'
                      "
                      label="Unidad de medida"
                      dense
                      readonly
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :value="`${crop.farmer.name} ${crop.farmer.lastname}`"
                      label="Nombre del productor"
                      dense
                      readonly
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :value="`${crop.farmer.mobile}`"
                      label="Teléfono del productor"
                      dense
                      readonly
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    v-if="index + 1 < crops.length"
                    class="pt-0 mb-2"
                  >
                    <v-divider />
                  </v-col>
                </v-row>
                <div v-if="crops.length == 0">No hay cultivos registrados</div>
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn color="grey darken-1" text @click="cropsDialog = false">
                  Cerrar
                </v-btn>
              </v-card-actions>

              <v-overlay :value="overlayCrops" absolute>
                <v-progress-circular indeterminate size="48" />
              </v-overlay>
            </v-card>
          </v-dialog>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-dialog
              v-model="dialog"
              v-if="dialog"
              max-width="600px"
              persistent
              scrollable
            >
              <v-form @submit.prevent="submitForm">
                <v-card>
                  <v-card-title>
                    <span class="text-button grey--text text--darken-1">{{
                      formTitle
                    }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="el nombre del producto"
                          >
                            <v-text-field
                              v-model="selectedItem.name"
                              dense
                              clearable
                              label="Nombre producto*"
                              :error-messages="errors"
                              required
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>*Indica campo requerido</small>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />

                    <v-btn color="grey darken-1" text @click="close">
                      Cancelar
                    </v-btn>

                    <v-btn
                      type="submit"
                      color="green darken-2"
                      :disabled="invalid"
                      text
                    >
                      {{ formActionBtn }}
                    </v-btn>
                  </v-card-actions>

                  <v-overlay :value="overlay" absolute>
                    <v-progress-circular indeterminate size="48" />
                  </v-overlay>
                </v-card>
              </v-form>
            </v-dialog>
          </ValidationObserver>

          <v-dialog
            v-model="dialogDelete"
            v-if="dialogDelete"
            max-width="600px"
            persistent
          >
            <v-card>
              <v-card-title
                class="grey--text text--darken-1 font-weight-regular text-subtitle-1"
              >
                ¿Está seguro que desea eliminar el producto
                <b>&nbsp;{{ selectedItem.name }}</b
                >?
              </v-card-title>

              <v-card-text>
                La información no podrá ser recuperada.
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="grey darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="red darken-1" text @click="deleteItemConfirm"
                  >Eliminar producto</v-btn
                >
              </v-card-actions>

              <v-overlay :value="overlay" absolute>
                <v-progress-circular indeterminate size="48" />
              </v-overlay>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="showCrops(item)">
          mdi-sprout
        </v-icon>
        <span class="grey--text text--lighten-3">|</span>
        <v-icon small class="mr-2 ml-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template #footer.prepend>
        <v-spacer class="py-6" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import restrictedActionMixin from '@/mixins/restrictedActionMixin';

import gql from 'graphql-tag';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('es');
dayjs.extend(localizedFormat);

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Debe ingresar {_field_}',
});

export default {
  name: 'ProductsAdminView',

  components: { ValidationProvider, ValidationObserver },

  mixins: [restrictedActionMixin],

  data: () => ({
    overlayCrops: false,
    cropsDialog: false,
    crops: [],

    overlay: false,

    dialog: false,
    dialogDelete: false,
    loading: false,
    options: {},
    totalProducts: 0,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Producto',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Acciones', sortable: false, value: 'actions', align: 'end' },
    ],
    products: [],
    selectedIndex: -1,
    selectedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
  }),

  computed: {
    formTitle() {
      return this.selectedIndex === -1
        ? 'Registrar producto'
        : 'Editar producto';
    },

    formActionBtn() {
      return this.selectedIndex === -1
        ? 'Registrar producto'
        : 'Editar producto';
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async initialize() {
      this.loading = true;
      const query = gql`
        query getProductsPaginated($input: GetProductsPaginatedInput!) {
          getProductsPaginated(input: $input) {
            products {
              name
              id
            }

            total
          }
        }
      `;

      const {
        data: { getProductsPaginated },
      } = await this.$apollo
        .query({
          query,
          variables: {
            input: {
              page: this.options.page,
              perPage: this.options.itemsPerPage,
            },
          },
          fetchPolicy: 'network-only',
        })
        .catch((error) => {
          this.loading = false;

          this.restrictedAction(error);
          return;
        });

      this.products = getProductsPaginated.products;
      this.totalProducts = getProductsPaginated.total;

      this.loading = false;
    },

    async showCrops(item) {
      this.selectedIndex = this.products.indexOf(item);
      this.selectedItem = Object.assign({}, item);
      this.cropsDialog = true;
      this.overlayCrops = true;

      const query = gql`
        query getCropsByProduct($input: GetCropsByProductInput) {
          getCropsByProduct(input: $input) {
            crops {
              date
              qty
              measurementType

              farmer {
                name
                lastname
                mobile
              }
            }
          }
        }
      `;

      const {
        data: { getCropsByProduct },
      } = await this.$apollo
        .query({
          query,
          variables: {
            input: {
              productId: this.selectedItem.id,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.overlayCrops = false;
          this.cropsDialog = false;

          this.restrictedAction(error);
          return;
        });

      this.crops = getCropsByProduct.crops;
      this.crops = this.crops.map((crop) => {
        crop.date = dayjs(crop.date).format('LL');

        return crop;
      });

      this.overlayCrops = false;
    },

    editItem(item) {
      this.selectedIndex = this.products.indexOf(item);
      this.selectedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.selectedIndex = this.products.indexOf(item);
      this.selectedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.overlay = true;

      const mutation = gql`
        mutation deleteProduct($input: DeleteProductInput) {
          deleteProduct(input: $input) {
            success
            message
          }
        }
      `;

      const {
        data: { deleteProduct },
      } = await this.$apollo
        .mutate({
          mutation,
          variables: {
            input: {
              id: this.selectedItem.id,
            },
          },
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      this.overlay = false;

      if (deleteProduct.success) {
        this.initialize();
        this.closeDelete();
      }

      this.$store.commit('snackbar/setSnack', {
        message: deleteProduct.message,
        color: deleteProduct.success ? 'success' : 'error',
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.defaultItem);
        this.selectedIndex = -1;
      });
    },

    async submitForm() {
      this.overlay = true;

      let mutation;
      const variables = this.selectedItem;

      if (this.selectedIndex > -1) {
        mutation = gql`
          mutation editProduct($input: DataProductInput) {
            editProduct(input: $input) {
              success
              message
            }
          }
        `;
      } else {
        mutation = gql`
          mutation addProduct($input: DataProductInput) {
            addProduct(input: $input) {
              success
              message
            }
          }
        `;
      }

      const { data } = await this.$apollo
        .mutate({
          mutation,
          variables: { input: variables },
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          this.overlay = false;

          this.restrictedAction(error);
          return;
        });

      let isSuccess, message;
      if (this.selectedIndex > -1) {
        isSuccess = data.editProduct.success;
        message = data.editProduct.message;
      } else {
        isSuccess = data.addProduct.success;
        message = data.addProduct.message;
      }

      if (isSuccess) {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'success',
        });

        this.initialize();

        this.close();
      } else {
        this.$store.commit('snackbar/setSnack', {
          message: message,
          color: 'error',
        });
      }

      this.overlay = false;
    },
  },
};
</script>
