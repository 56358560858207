export default {
  methods: {
    restrictedAction(error, message = 'Requiere conexión a internet') {
      if (error.message == 'Network error: Failed to fetch') {
        this.$store.commit('snackbar/setSnack', {
          message,
          color: 'warning',
        });
      }
    },
  },
};
